import { useContext } from "react";
import { Box, Text, useToast } from "@chakra-ui/react";
import {
  CustomInput,
  ErrorMsg,
  CustomToast,
  CustomButton,
} from "components/shared";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { login } from "apis/tickets";
import { AuthContext } from "context/user-auth";

import { Formik, Form } from "formik";
import loginValidations from "validations/login";

const LoginForm = ({ setPersonalInfo }) => {
  const toast = useToast();
  const { setAuthState, authState } = useContext(AuthContext);

  const { mutate, isLoading } = useMutation(login, {
    onSuccess: (res) => {
      localStorage.setItem("token", res?.data?.token);

      setAuthState({
        isAuthenticated: true,
        user: res?.data.user,
        token: res?.data.token,
      });
    },
    onError: (err) => {
      const errors = err?.response?.data;

      if (errors?.password) {
        toast({
          render: (props) => {
            return (
              <CustomToast
                title="حدث خطأ"
                description={errors?.password}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      } else if (errors?.email) {
        toast({
          render: (props) => {
            return (
              <CustomToast
                title="حدث خطأ"
                description={errors?.email}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      }
    },
  });

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={loginValidations}
      onSubmit={(values) => {
        mutate(values);
      }}>
      {(formik) => {
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Text fontWeight="bold" mb="20px" fontSize="26px" color="#383838">
              مرحبًا بعودتك!
            </Text>
            <Text mb="20px" color="#a2a2a2">
              قم بإدخال بياناتك لتتمكن من الوصول إلى حسابك واستكشاف جميع
              خدماتنا. إذا كنت بحاجة إلى مساعدة، نحن هنا لدعمك.{" "}
            </Text>

            <Box mb="20px">
              <CustomInput
                placeholder=" الايميل"
                onChange={formik.handleChange("email")}
                onBlur={formik.handleBlur("email")}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email && (
                <ErrorMsg text={formik.errors.email} />
              )}
            </Box>

            <Box mb="20px">
              <CustomInput
                placeholder="الرقم السرى"
                type="password"
                onChange={formik.handleChange("password")}
                onBlur={formik.handleBlur("password")}
                value={formik.values.password}
              />
              {formik.errors.password && formik.touched.password && (
                <ErrorMsg text={formik.errors.password} />
              )}
            </Box>

            <Text mb="20px">
              هل نسيت الرقم السرى ؟{" "}
              <Text
                as={Link}
                to="/forgetPassword"
                display="inline"
                color="text.mainColor">
                اضغط هنا
              </Text>
            </Text>

            <CustomButton
              type="submit"
              mt="25px"
              mb="5px"
              name="ارسال"
              loading={isLoading}
            />

            <Text textAlign="center">
              ليس لديك حساب ؟{" "}
              <Text
                as={Link}
                to="/signup"
                fontWeight="bold"
                color="text.mainColor">
                سجل الأن
              </Text>
            </Text>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
