import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import { CustomContainer } from "components/shared";
import ReviewCard from "components/dashboard/review-card";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getClientById } from "apis/tickets";
import moment from "moment";

const Client = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery(["get client ", id], getClientById);

  const clientInfo = data?.data?.clientInfo;
  const totalReviews = data?.data?.totalReviews;
  const averageReviews = data?.data?.averageRating;

  return (
    <Box my="50px">
      <CustomContainer>
        <Grid
          gridTemplateColumns={[
            "repeat(1,1fr)",
            "repeat(1,1fr)",
            "1fr 2fr",
            "1fr 2fr",
          ]}
          gap="25px">
          <Box>
            <Text mb="20px">البيانات الشخصية</Text>
            <Box
              bg="#f7f7f8"
              borderRadius="15px"
              border="2px solid #e0e0e0"
              p="20px">
              <Flex gap="30px" mb="15px" alignItems="center">
                <Grid
                  placeItems="center"
                  w="70px"
                  h="70px"
                  borderRadius="50%"
                  bg="bg.mainColor"
                  color="white">
                  {clientInfo?.name.substring(0, 2)}
                </Grid>
                <Box>
                  <Text fontWeight="bold">{clientInfo?.name}</Text>
                  <Text>0{clientInfo?.phoneNumber}</Text>
                </Box>
              </Flex>

              <Flex gap="40px" mb="15px" alignItems="center">
                <Box fontSize={["14px", "16px", "", ""]}>
                  <Text>عدد التقيمات</Text>
                  <Text fontWeight="bold">{totalReviews}</Text>
                </Box>
                <Box fontSize={["14px", "16px", "", ""]}>
                  <Text>متوسط التقيم</Text>
                  <Text fontWeight="bold">{averageReviews}</Text>
                </Box>
                <Box fontSize={["14px", "16px", "", ""]}>
                  <Text>تاريخ التسجيل</Text>
                  <Text fontWeight="bold">
                    {moment(clientInfo?.createdAt).format("DD-MM-yyyy")}
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Box>

          <Box>
            <Text mb="20px">التقيمات</Text>
            {data?.data?.reviews.length == 0 ? (
              <Grid
                placeItems="center"
                bg="#f7f7f8"
                borderRadius="15px"
                border="2px solid #e0e0e0"
                h="82%">
                <Text fontSize={["20px", "30px", "45px", ""]}>
                  هذا العميل لم يكتب اى تعليق بعد
                </Text>
              </Grid>
            ) : (
              <Grid
                gridTemplateColumns={[
                  "repeat(1,1fr)",
                  "repeat(2,1fr)",
                  "repeat(2,1fr)",
                  "repeat(2,1fr)",
                ]}
                gap="15px">
                {data?.data?.reviews?.map((e, i) => {
                  return (
                    <>
                      <ReviewCard
                        name={clientInfo?.name}
                        date={moment(e?.date).format("DD-MM-yyyy")}
                        notes={e?.questions[0]?.notes}
                        rating={e?.questions[0]?.rating}
                        reviews={e?.questions}
                      />
                    </>
                  );
                })}
              </Grid>
            )}
          </Box>
        </Grid>
      </CustomContainer>
    </Box>
  );
};

export default Client;
