import * as Yup from "yup";

const signupProjectValidations = Yup.object().shape({
  projectName: Yup.string()
    .min(3, "يجب كتابة احرف اكثر من ثلاثة احرف")
    .max(100, "يجب ان تكون عدد الحروف اقل من 100")
    .required("هذا الحقل مطلوب"),
  addressName: Yup.string()
    .min(3, "يجب ان تكون عدد الحروف اكتر من 3")
    .max(100, "يجب ان تكون عدد الحروف اقل من 100")
    .required("هذا الحقل مطلوب"),
});

export default signupProjectValidations;
