import { Box } from "@chakra-ui/react";
import {
  Hero,
  ContactUs,
  CallToAction,
  Features,
  HowItWorks,
} from "components/landing-page";

const LandingPage = () => {
  return (
    <Box>
      <Hero />
      <Features />
      <HowItWorks />
      {/* <Benefits /> */}
      <CallToAction />
      <ContactUs />
    </Box>
  );
};

export default LandingPage;
