import { Flex, Text, Box, Grid } from "@chakra-ui/react";
import { FaFire } from "react-icons/fa";

const Card = ({ img, name, salesNumber, ...props }) => {
  return (
    <Flex
      bg="white"
      borderRadius="10px"
      // border="1px solid #e7e3e3"
      p="15px"
      py="20px"
      justifyContent="space-between"
      color="white"
      {...props}>
      <Grid
        placeItems="center"
        w={["60px", "60px", "50px", "60px"]}
        h={["60px", "60px", "50px", "60px"]}
        borderRadius="50%"
        cursor="default"
        bg="rgba(255 ,255,255,0.4)">
        <Box as={FaFire} color="white" />
      </Grid>
      <Box>
        <Text fontSize={["30px", "30px", "20px", "30px"]}>{salesNumber}</Text>
        <Text>{name}</Text>
      </Box>
    </Flex>
  );
};

export default Card;
