import { useState, forwardRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Box, Button, Text } from "@chakra-ui/react";
import { GoCalendar } from "react-icons/go";
import moment from "moment";
import "styles/date-picker.scss";
import "react-datepicker/dist/react-datepicker.css";

const CustomDate = ({
  setDate,
  start,
  w = "100%",
  dateFormat = "dd-MM-YYYY",
  border,
  buttonH = "50px",
  showMonthYearPicker,
  handleDate,
  placeholder,
  defaultDate,
  minDate,
  maxDate,
  ...props
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [change, setChange] = useState(false);
  //we use this to enable prev and next with calendar ==> go to calendar file
  useEffect(() => {
    if (start) {
      setStartDate(start);
    }
  }, [start]);

  useEffect(() => {
    if (defaultDate) {
      setChange(true);
      setStartDate(new Date(defaultDate));
    }
  }, []);

  const days = ["ح", "ت", "ث", "ع", "خ", "ج", "س"];
  const months = [
    "يناير",
    "فبراير",
    "مارس",
    "إبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "mm/dd/yyyy",
    },
  };

  const CalendarInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      _focus={{}}
      onClick={onClick}
      ref={ref}
      h={buttonH}
      borderRadius="5px"
      color="text.1100"
      fontWeight="normal"
      bg="#f8f9fa"
      border="1px solid #e7e3e3"
      _hover={{ bg: "#f1f3f4" }}
      {...props}>
      {placeholder ? (change ? value : placeholder) : value}

      {/* {value} */}

      <Box as={GoCalendar} pointerEvents="none" ms="10px" />
    </Button>
  ));

  return (
    <Box pos="relative">
      <Box
        locale={locale}
        as={DatePicker}
        className="calen"
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          setChange(true);

          if (setDate) {
            setDate(date);
          }

          if (handleDate) {
            handleDate(moment(date).locale("en").format("DD-MM-yyyy"));
          }
        }}
        bg="green"
        boxShadow="0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06)"
        h="40px"
        ps="20px"
        borderRadius="5px"
        cursor="pointer"
        w={w}
        _focus={{ outline: "none" }}
        dateFormat={dateFormat}
        calendarClassName="calen"
        dayClassName={() => {
          return "day";
        }}
        todayButton="تاريخ النهاردة"
        customInput={<CalendarInput />}
        showMonthYearPicker={showMonthYearPicker ? true : false}
        minDate={minDate}
        maxDate={maxDate}
        showMonthDropdown
        yearDropdownItemNumber={60}
        showYearDropdown
        scrollableYearDropdown>
        {/* {change ? (
          <Text
            textAlign="center"
            cursor="pointer"
            bg="red"
            color="white"
            fontWeight="bold"
            p="5px"
            onClick={() => {
              setChange(null);
              handleDate(null);
              setStartDate(new Date());
            }}>
            محو التاريخ
          </Text>
        ) : null} */}
      </Box>
    </Box>
  );
};

export default CustomDate;
