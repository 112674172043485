import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Box,
} from "@chakra-ui/react";
import CustomButton from "./custom-button";

const CustomModal = ({
  title,
  titleDir = "center",
  show,
  setShow,
  children,
  overflow = "inside",
  titleMarginBottom = "40px",
  ...props
}) => {
  return (
    <>
      <Modal
        isCentered
        onClose={() => setShow(false)}
        isOpen={show}
        motionPreset="slideInBottom"
        scrollBehavior={overflow}
        zIndex="111111">
        <ModalOverlay />
        <ModalContent p="40px" maxW="550px" mx="10px" {...props}>
          <ModalHeader
            textAlign={titleDir}
            fontSize={["20px", "22px", "32px", "32px"]}
            p="0"
            color="text.600"
            mb={titleMarginBottom}>
            {title}
          </ModalHeader>
          <ModalCloseButton
            top="20px"
            right="20px"
            fontSize="15px"
            bg="bg.mainColor"
            color="white"
            borderRadius="50%"
            p="20px"
            _focus={{}}
            _hover={{ bg: "bg.hoverMainColor" }}
            _active={{ bg: "bg.400" }}
          />
          <ModalBody p="0">
            <Flex justifyContent="center">
              <Box w={["98%","85%","85%","85%"]}>{children}</Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomModal;
