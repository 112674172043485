import * as Yup from "yup";

// Define the schema for a single question
const questionSchema = Yup.object().shape({
  serviceName: Yup.string().required("Service name is required"),
  questionInAr: Yup.string().required("Question in Arabic is required"),
  questionInEn: Yup.string().required("Question in English is required"),
});

// Define the main schema
const rateSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  rateShapeType: Yup.string().required("Rate shape type is required"),
  questions: Yup.array()
    .of(questionSchema)
    .min(1, "At least one question is required") // Ensures there is at least one question
    .required("Questions are required"),
});

export default rateSchema;
