import { useState } from "react";
import { Box, Flex, Grid, Text, Spinner } from "@chakra-ui/react";
import CustomAvatar from "./custom-avatar";
import axios from "axios";
import ImageUploading from "react-images-uploading";
import { useMutation } from "react-query";
//import { uploadFile } from "apis/uploadFile";

const CustomUploadFile = ({
  formikChange,
  defaultImage = null,
  radius,
  width,
  textTitle,
  helperText,
  profileData,
  disabled,
  letter,
  required,
  requiredMessage,
  setUploadImageLoading,
  acceptType = ["jpg", "png"],
}) => {
  const [images, setImages] = useState([]);

  const maxNumber = 1;

  //response store image come from backend or the image that i upload
  const [response, setResponse] = useState(profileData?.image);

  //   const { mutate, isLoading } = useMutation(uploadFile, {
  //     onMutate: () => {
  //       if (setUploadImageLoading) {
  //         setUploadImageLoading(true);
  //       }
  //     },
  //     onSuccess: (res) => {
  //       setResponse(res.data.filename);
  //       if (setUploadImageLoading) {
  //         setUploadImageLoading(false);
  //       }
  //       formikChange(res.data.filename);
  //     },
  //     onError: (err) => {
  //       console.log(err);
  //     },
  //   });

  const onChange = (imageList, addUpdateIndex) => {
    const fileDetails = (e) => {
      // const fd = new FormData();
      // fd.append("file", imageList[0].file);

      formikChange(imageList[0].file);
      //   mutate(fd);
      // axios.post('/uploads/image', fd).then(res => {
      //   setResponse(res.data.filename);
      //   formikChange(res.data.filename);
      // });
    };
    fileDetails();
    setImages(imageList);
  };

  return (
    <ImageUploading
      //multiple
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
      //allowNonImageType
      acceptType={acceptType}>
      {({ imageList, onImageUpload, onImageUpdate, onImageRemove, errors }) => (
        // write your building UI
        <Flex alignItems={helperText ? "initial" : "center"} mb="10px">
          {/* {!isLoading && (
              )} */}
          <CustomAvatar
            radius={radius}
            width={width}
            image={
              images[0]?.data_url || defaultImage
              // response
              //   ? `${process.env.REACT_APP_API_BASE_URL}/uploads/image/${response}`
              //   : defaultImage
            }
            letter={letter}
            m="0"
          />

          {/* {isLoading && (
            <Grid
              placeItems="center"
              w="100px"
              h="100px"
              bg="bg.300"
              borderRadius={radius ? "50%" : "10px"}
              fontSize="12px"
              color="white"
            >
              <Flex alignItems="center">
                <Text me="5px" fontWeight="bold">
                  Loading
                </Text>
                <Spinner />
              </Flex>
            </Grid>
          )} */}

          <Box ms="16px">
            {helperText ? (
              <Text
                mb="5px"
                fontWeight="bold"
                color="#181F27"
                fontSize="14px"
                mt="8px">
                {helperText}
              </Text>
            ) : null}

            {errors && (
              <Box>
                {errors.maxNumber && (
                  <Text color="red" fontWeight="bold" fontSize="14px">
                    you have reach max numper
                  </Text>
                )}
                {errors.acceptType && (
                  <Text color="red" fontWeight="bold" fontSize="14px">
                    this type is not supported
                  </Text>
                )}
              </Box>
            )}

            <Text
              color={required ? "red" : "text.1000"}
              fontWeight="semibold"
              cursor="pointer"
              fontSize="14px"
              onClick={
                disabled
                  ? null
                  : imageList.length > 0
                  ? () => onImageUpdate(0)
                  : onImageUpload
              }>
              {response == null
                ? required
                  ? requiredMessage
                  : textTitle || "رفع صورة"
                : "تعديل الصورة"}
            </Text>
          </Box>
        </Flex>
      )}
    </ImageUploading>
  );
};

export default CustomUploadFile;
