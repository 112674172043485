import { Flex, Image, Heading, Text, CircularProgress } from "@chakra-ui/react";

const Loading = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minH="40vh"
    >
      <Flex>
        <Heading as="h3" fontWeight="bold" mb="10px" me="10px">
          تحميل
        </Heading>
        <CircularProgress isIndeterminate color="orange" />
      </Flex>
    </Flex>
  );
};

export default Loading;
