import { useState, useContext } from "react";
import {
  Box,
  Text,
  Grid,
  Textarea,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import {
  CustomInput,
  ErrorMsg,
  CustomButton,
  Label,
  CustomModal,
} from "components/shared";
import { Formik, Form, FieldArray } from "formik";
import { Rating } from "react-simple-star-rating";
import { IoMdAdd } from "react-icons/io";
import { RiDeleteBin6Line, RiEyeFill } from "react-icons/ri";
import rateSchema from "validations/rate";
import { useMutation, useQuery } from "react-query";
import { getReviewTemplate, updateReviewTemplate } from "apis/tickets";
import { AuthContext } from "context/user-auth";

const Question = ({
  remove,
  index,
  formik,
  question,
  serviceName,
  questionInAr,
  questionInEn,
  length,
}) => {
  return (
    <Box
      border="1px solid #e7e7e7"
      borderRadius="5px"
      p="10px"
      mb="20px"
      pos="relative">
      <Box>
        <Label text="اسم الخدمة" />
        <CustomInput
          placeholder="اكتب الاسم هنا"
          name={serviceName}
          value={question.serviceName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.errors.questions &&
          formik.errors.questions[index] &&
          formik.errors.questions[index].serviceName &&
          formik.touched.questions &&
          formik.touched.questions[index] &&
          formik.touched.questions[index].serviceName && (
            <ErrorMsg text={formik.errors.questions[index].serviceName} />
          )}
      </Box>

      <Box>
        <Label text="السؤال بالعربى " />
        <CustomInput
          placeholder="اكتب السؤال هنا"
          name={questionInAr}
          value={question.questionInAr}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.errors.questions &&
          formik.errors.questions[index] &&
          formik.errors.questions[index].questionInAr &&
          formik.touched.questions &&
          formik.touched.questions[index] &&
          formik.touched.questions[index].questionInAr && (
            <ErrorMsg text={formik.errors.questions[index].questionInAr} />
          )}
      </Box>

      <Box>
        <Label text="السؤال بالانجليزى" />
        <CustomInput
          placeholder="اكتب السؤال هنا هنا"
          name={questionInEn}
          value={question.questionInEn}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.errors.questions &&
          formik.errors.questions[index] &&
          formik.errors.questions[index].questionInEn &&
          formik.touched.questions &&
          formik.touched.questions[index] &&
          formik.touched.questions[index].questionInEn && (
            <ErrorMsg text={formik.errors.questions[index].questionInEn} />
          )}
      </Box>

      {length > 1 ? (
        <Grid
          placeItems="center"
          w="35px"
          h="35px"
          borderRadius="50%"
          cursor="pointer"
          border="1px solid #e7e3e3"
          bg="white"
          pos="absolute"
          top="-15px"
          left="-15px"
          color="red"
          transition="0.3s"
          _hover={{
            bg: "red",
            color: "white",
            border: "none",
          }}
          onClick={() => remove(index)}>
          <Box as={RiDeleteBin6Line} />
        </Grid>
      ) : null}
    </Box>
  );
};

const EditFormModal = ({ data, closeModal, refetch }) => {
  const { authState } = useContext(AuthContext);
  const user = authState?.user;

  const { mutate, isLoading } = useMutation(updateReviewTemplate, {
    onSuccess: () => {
      closeModal(false);
      refetch();
    },
  });

  return (
    <Box>
      <Formik
        initialValues={{
          title: data?.title,
          buttonBgColor: data?.buttonBgColor,
          buttonTextColor: data?.buttonTextColor,
          rateShapeType: data?.rateShapeType,
          questions: data?.questions,
        }}
        validationSchema={rateSchema}
        onSubmit={(values) => {
          mutate({ id: user?._id, data: values });
          //handleLogin({ email: values.email, password: values.password });
        }}>
        {(formik) => {
          return (
            <Box p="20px" maxH="400px" overflow="auto">
              <Form onSubmit={formik.handleSubmit}>
                <Box mb="20px">
                  <Label text="عنوان للنموذج" />
                  <CustomInput
                    type="text"
                    placeholder="الاسم هنا"
                    onChange={formik.handleChange("title")}
                    onBlur={formik.handleBlur("title")}
                    value={formik.values.title}
                  />
                  {formik.errors.title && formik.touched.title && (
                    <ErrorMsg text={formik.errors.title} />
                  )}
                </Box>

                <Box mb="20px">
                  <Label text="التقيم من خلال " />
                  <RadioGroup
                    defaultValue="stars"
                    onChange={(e) => formik.setFieldValue("rateShapeType", e)}
                    value={formik.values.rateShapeType}>
                    <Stack spacing={5} direction="row">
                      <Radio
                        colorScheme="orange"
                        value="stars"
                        _focus={{}}
                        _active={{}}>
                        نجوم
                      </Radio>
                      <Radio
                        colorScheme="orange"
                        value="emojies"
                        _focus={{}}
                        _active={{}}>
                        ايموجى
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Box>

                <Box mb="20px">
                  <Label text=" لون زرار الارسال" />
                  <Grid gridTemplateColumns="repeat(2,1fr)" gap="15px">
                    <Flex alignItems="center">
                      <Text>لون الخلفية</Text>
                      <Box pos="relative">
                        <Box
                          as="label"
                          for="input_color_bg"
                          cursor="pointer"
                          w="40px"
                          h="20px"
                          bg={formik.values.buttonBgColor || "bg.mainColor"}
                          display="block"
                          ms="10px"
                          boxShadow="0 0 15px -4px #5e5e5e"
                        />
                        <Input
                          type="color"
                          id="input_color_bg"
                          visibility="hidden"
                          pos="absolute"
                          h="5px"
                          value={formik.values.buttonBgColor || "#3861fb"}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "buttonBgColor",
                              e?.target.value
                            );
                          }}
                        />
                      </Box>
                    </Flex>

                    <Flex alignItems="center">
                      <Text>لون النص</Text>
                      <Box pos="relative">
                        <Box
                          as="label"
                          for="input_color_text"
                          cursor="pointer"
                          w="40px"
                          h="20px"
                          bg={formik.values.buttonTextColor || "white"}
                          display="block"
                          ms="10px"
                          boxShadow="0 0 15px -4px #5e5e5e"
                        />
                        <Input
                          type="color"
                          id="input_color_text"
                          visibility="hidden"
                          pos="absolute"
                          h="5px"
                          value={formik.values.buttonTextColor || "ffffff"}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "buttonTextColor",
                              e?.target.value
                            );
                          }}
                        />
                      </Box>
                    </Flex>
                  </Grid>
                </Box>

                <FieldArray name="questions">
                  {({ push, remove }) => (
                    <>
                      {formik?.values?.questions?.map((q, i) => {
                        const serviceName = `questions[${i}].serviceName`;
                        const questionInAr = `questions[${i}].questionInAr`;
                        const questionInEn = `questions[${i}].questionInEn`;

                        return (
                          <Question
                            formik={formik}
                            serviceName={serviceName}
                            questionInAr={questionInAr}
                            questionInEn={questionInEn}
                            question={q}
                            remove={remove}
                            index={i}
                            length={formik?.values?.questions?.length}
                          />
                        );
                      })}
                      <Grid
                        placeItems="center"
                        w="50px"
                        h="50px"
                        borderRadius="50%"
                        border="1px solid #e7e7e7"
                        m="auto"
                        cursor="pointer"
                        transition="0.3s"
                        _hover={{
                          bg: "bg.mainColor",
                          color: "white",
                        }}
                        onClick={() =>
                          push({
                            serviceName: "",
                            questionInAr: "",
                            questionInEn: "",
                          })
                        }>
                        <Box as={IoMdAdd} fontSize="20px" />
                      </Grid>
                    </>
                  )}
                </FieldArray>

                <CustomButton
                  type="submit"
                  mt="25px"
                  mb="5px"
                  loading={isLoading}
                  name="حفظ"
                />
              </Form>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

export default EditFormModal;
