import * as Yup from "yup";

const signupPersonalValidations = Yup.object().shape({
  firstName: Yup.string()

    .max(100, "يجب ان تكون عدد الحروف اقل من 100")
    .required("هذا الحقل مطلوب"),
  lastName: Yup.string()

    .max(100, "يجب ان تكون عدد الحروف اقل من 100")
    .required("هذا الحقل مطلوب"),
  email: Yup.string().email("الايميل غير صحيح").required("هذا الحقل مطلوب"),
  phone: Yup.string()
    .min(10, "يجب ان يكون الهاتف 11 رقم")
    .max(11, "يجب ان يكون الهاتف 11 رقم")
    .required("هذا الحقل مطلوب")
    .test("phoneStartsWithZero", "startWithZero", (phone) =>
      phone && phone[3] === "0" ? false : true
    ),
  password: Yup.string()
    .min(6, "يجب ان تكون عدد الحروف اكتر من 6")
    .max(100, "يجب ان تكون عدد الحروف اقل من 100")
    .required("هذا الحقل مطلوب"),
  confirmPassword: Yup.string()
    .min(6, "يجب ان تكون عدد الحروف اكتر من 6")
    .max(100, "يجب ان تكون عدد الحروف اقل من 100")
    .required("هذا الحقل مطلوب")
    .oneOf([Yup.ref("password"), null], "الرقم السري غير متطابق"),
});

export default signupPersonalValidations;
