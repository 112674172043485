import * as Yup from "yup";

const clientSchema = Yup.object().shape({
  name: Yup.string().required("هذا الحقل مطلوب"),
  phoneNumber: Yup.string()
    .required("هذا الحقل مطلوب")
    .min(10, "يجب ان يكون الهاتف 11 رقم")
    .max(11, "يجب ان يكون الهاتف 11 رقم"),
});

export default clientSchema;
