import { Box } from "@chakra-ui/react";
import { ForgetPasswordForm } from "components/forget-password";

const ForgetPassword = () => {
  return (
    <Box
      w={["90%", "90%", "50%", "50%"]}
      my="50px"
      mx="auto"
      p={["30px", "45px", "50px", "50px"]}
      borderRadius="10px"
      border="2px solid #e0e0e0">
      <ForgetPasswordForm />
    </Box>
  );
};

export default ForgetPassword;
