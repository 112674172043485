import { useContext } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from "@chakra-ui/react";
import { PersonalInfo, ResturantInfo } from "components/profile";
import { CustomContainer } from "components/shared";
import { AuthContext } from "context/user-auth";

const Profile = () => {
  const { authState } = useContext(AuthContext);
  const user = authState.user;

  return (
    <Box my="50px">
      <CustomContainer>
        <Box
          w={["100%", "600px", "600px", ""]}
          m="auto"
          bg="white"
          borderRadius="20px"
          overflow="hidden"
          boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px">
          <Tabs>
            <TabList>
              <Tab
                py="15px"
                w="50%"
                fontSize={["14px", "", "16px", ""]}
                _selected={{ color: "white", bg: "blue.500" }}>
                البيانات الشخصية
              </Tab>
              <Tab
                py="15px"
                w="50%"
                fontSize={["14px", "", "16px", ""]}
                _selected={{ color: "white", bg: "blue.500" }}>
                بيانات المطعم
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <PersonalInfo
                  firstName={user?.firstName}
                  lastName={user?.lastName}
                  phone={"0" + user?.phone}
                  email={user?.email}
                />
              </TabPanel>
              <TabPanel>
                <ResturantInfo
                  profileImgUrl={user?.profileImgUrl}
                  addressName={user?.addressName}
                  projectName={user?.projectName}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </CustomContainer>
    </Box>
  );
};

export default Profile;
