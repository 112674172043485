import { Box, Text, Image, Grid, Flex } from "@chakra-ui/react";
import logo from "assets/images/logo1.svg";
import { FaFacebookF, FaWhatsapp, FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";

const SocialMedaiLinks = ({ icon, ...props }) => {
  return (
    <Grid
      placeItems="center"
      border="1px solid black"
      w="50px"
      h="50px"
      borderRadius="5px"
      cursor="pointer"
      {...props}>
      <Box as={icon} fontSize="20px" />
    </Grid>
  );
};

const Footer = () => {
  return (
    <Box background="#ededed" borderTop="2px solid #e0e0e0" pt="60px" pb="30px">
      <Image w={["120px", "180px", "180px", "180px"]} src={logo} m="auto" />
      <Text
        fontSize={["14px", "16px", "", ""]}
        textAlign="center"
        w={["90%", "60%", "50%", "40%"]}
        m="auto"
        my="15px"
        opacity="0.8">
        جميع الحقوق محفوظة © 2024 لمنصة رأي. منصة رأي تتيح لك جمع وتحليل آراء
        العملاء بسهولة لتحسين خدماتك وزيادة رضا عملائك. استخدم تقنياتنا المبتكرة
        لتحويل ملاحظات العملاء إلى قرارات فعّالة تساعد في نمو عملك.{" "}
      </Text>
      {/* <Flex alignItems="center" justifyContent="center" w="50%" m="auto">
        <SocialMedaiLinks icon={FaFacebookF} me="10px" />
        <SocialMedaiLinks icon={FaWhatsapp} me="10px" />
        <SocialMedaiLinks icon={FaTwitter} me="10px" />
        <SocialMedaiLinks icon={FaInstagram} />
      </Flex> */}

      {/* <Text textAlign="center" w="50%" m="auto" mt="55px">
        Copyrights All rights revirsed by ibrahem magdy
      </Text> */}
    </Box>
  );
};

export default Footer;
