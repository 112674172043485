import { Text, Box, Flex } from "@chakra-ui/react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

// ChartJS.defaults.font.family = "cairo";

export const options = {
  plugins: {
    legend: {
      display: false,
      position: "bottom",
    },
    datalabels: {
      color: "#ffffff",
      //   anchor: "end",
      align: "center",
      font: { size: 18 },
      textAlign: "center",
      formatter: (value, context) => {
        const dataPoints = context.chart.data.datasets[0].data;
        function totalSum(total, datapoint) {
          return total + datapoint;
        }
        const totalValue = dataPoints.reduce(totalSum, 0);
        const percentageValue = ((value / totalValue) * 100).toFixed(1);
        return `${percentageValue}%`;
      },
    },
  },
};

const colors = ["#fca053", "#1c536b", "#a2e0f0"];

export const data = {
  labels: ["فى المطعم", "ديلافيرى", "تيك اواى"],
  datasets: [
    {
      // label: "# of Votes",
      data: [5, 2, 5],
      backgroundColor: colors,
      borderColor: colors,
      borderWidth: 1,
    },
  ],
};

const CustomLegend = ({ name, color }) => {
  return (
    <Flex alignItems="center">
      <Box w="15px" h="15px" bg={color} borderRadius="50%" me="5px" />
      <Text>{name}</Text>
    </Flex>
  );
};

const OrderTypeChart = () => {
  return (
    <>
      <Pie options={options} data={data} />

      <Flex justifyContent="center" alignItems="center" gap="20px" mt="15px">
        {data?.labels.map((e, i) => {
          return <CustomLegend name={e} color={colors[i]} />;
        })}
      </Flex>
    </>
  );
};

export default OrderTypeChart;
