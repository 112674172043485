import { useContext } from "react";
import { Box, Text, useToast } from "@chakra-ui/react";
import {
  CustomInput,
  ErrorMsg,
  CustomToast,
  CustomButton,
} from "components/shared";
import { useMutation } from "react-query";
import { updateProfile } from "apis/tickets";
import { AuthContext } from "context/user-auth";
import { Formik, Form } from "formik";

const PersonalInfo = ({ firstName, lastName, phone, email }) => {
  const { setAuthState } = useContext(AuthContext);
  const toast = useToast();

  const { mutate, isLoading } = useMutation(updateProfile, {
    onSuccess: (res) => {
      setAuthState((prev) => ({
        ...prev,
        user: res.data.user,
      }));

      toast({
        render: (props) => {
          return (
            <CustomToast
              title="تعديل الملف الشخصى"
              description=" تم تعديل الملف الشخصى بنجاح"
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  return (
    <Formik
      initialValues={{
        firstName: firstName || "",
        lastName: lastName || "",
        phone: phone || "",
        email: email || "",
      }}
      onSubmit={(values) => {
        mutate(values);
        //console.log({ ...personalInfo, ...values });
      }}
      //   validationSchema={signupValidations}
    >
      {(formik) => {
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Text fontWeight="bold" mb="20px" fontSize="26px" color="#383838">
              التسجيل
            </Text>
            <Text mb="20px" color="#a2a2a2">
              سجل الأن واستمتع بجميع الخدمات التى نقدمها او كن شريك لنا واعمل
              معنا وحقق الربح الذى تريده
            </Text>

            <Box mb="20px">
              <CustomInput
                label="الأسم الأول"
                placeholder="الأسم الأول"
                onChange={formik.handleChange("firstName")}
                onBlur={formik.handleBlur("firstName")}
                value={formik.values.firstName}
              />
              {formik.errors.firstName && formik.touched.firstName && (
                <ErrorMsg text={formik.errors.firstName} />
              )}
            </Box>
            <Box mb="20px">
              <CustomInput
                label="الأسم الأخير"
                placeholder="الأسم الأخير"
                onChange={formik.handleChange("lastName")}
                onBlur={formik.handleBlur("lastName")}
                value={formik.values.lastName}
              />
              {formik.errors.lastName && formik.touched.lastName && (
                <ErrorMsg text={formik.errors.lastName} />
              )}
            </Box>
            <Box mb="20px">
              <CustomInput
                label=" الايميل"
                placeholder=" الايميل"
                onChange={formik.handleChange("email")}
                onBlur={formik.handleBlur("email")}
                value={formik.values.email}
                disabled
              />
              {formik.errors.email && formik.touched.email && (
                <ErrorMsg text={formik.errors.email} />
              )}
            </Box>

            <Box mb="20px">
              <CustomInput
                label="الهاتف"
                placeholder="الهاتف"
                type="number"
                onChange={formik.handleChange("phone")}
                onBlur={formik.handleBlur("phone")}
                value={formik.values.phone}
              />
              {formik.errors.phone && formik.touched.phone && (
                <ErrorMsg text={formik.errors.phone} />
              )}
            </Box>

            <CustomButton
              type="submit"
              mt="25px"
              mb="5px"
              name="حفظ"
              loading={isLoading}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default PersonalInfo;
