import axios from "axios";

//api for clients
export const createClient = (data) => {
  return axios.post(`/clients/create`, data);
};

export const getClients = ({ queryKey }) => {
  const { search, page, itemsPerPage } = queryKey[1];

  return axios.get(
    `/clients?search=${search}&page=${page}&itemsPerPage=${itemsPerPage} `
  );
};

export const getClientById = ({ queryKey }) => {
  return axios.get(`/clients/${queryKey[1]} `);
};

export const updateClient = ({ id, data }) => {
  return axios.patch(`/clients/update/${id}`, data);
};

//api for review-template
export const createReviewTemplate = (data) => {
  return axios.post(`/reviewTemplate/create`, data);
};

export const getReviewTemplate = ({ queryKey }) => {
  return axios.get(`/reviewTemplate/${queryKey[1]}`);
};

export const updateReviewTemplate = ({ id, data }) => {
  return axios.patch(`/reviewTemplate/update/${id}`, data);
};

//api for reviews
export const createReview = (data) => {
  return axios.post(`/reviews/create`, data);
};

export const getReviews = ({ queryKey }) => {
  const { date, starsCount, question, page, itemsPerPage } = queryKey[1];

  return axios.get(
    `/reviews?createdAt=${date}&rating=${starsCount}&questionId=${question}&page=${page}&itemsPerPage=${itemsPerPage}  `
  );
};

export const getReviewsWithLimit = ({ queryKey }) => {
  return axios.get(`/reviews/withLimit?limit=${queryKey[1]} `);
};

export const getReport = ({ queryKey }) => {
  return axios.get(`/report `);
};

export const createContact = (data) => {
  return axios.post("/contact/create", data);
};

//api for user auth
export const signup = (data) => {
  const formData = new FormData();

  if (data?.profileImg) {
    formData.append("profileImg", data.profileImg);
  }
  formData.append("firstName", data.firstName);
  formData.append("lastName", data.lastName);
  formData.append("phone", data.phone);
  formData.append("email", data.email);
  formData.append("password", data.password);
  formData.append("confirmPassword", data.confirmPassword);
  formData.append("projectName", data.projectName);
  formData.append("addressName", data.addressName);

  return axios.post("/user/signup", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const isUserExist = (email) => {
  return axios.post("/user/isUserExist", { email });
};

export const login = (data) => {
  return axios.post("/user/login", data);
};

export const forgotPassword = (data) => {
  return axios.post("/user/forgot-password", data);
};

export const resetPassword = ({ token, data }) => {
  return axios.post(`/user/reset-password/${token}`, data);
};

export const updateProfile = (data) => {
  const formData = new FormData();

  // Append profile image and other fields to FormData
  if (data.profileImg) {
    formData.append("profileImg", data.profileImg);
  }

  if (data.firstName) {
    formData.append("firstName", data.firstName);
  }
  if (data.lastName) {
    formData.append("lastName", data.lastName);
  }
  if (data.phone) {
    formData.append("phone", data.phone);
  }
  if (data.projectName) {
    formData.append("projectName", data.projectName);
  }
  if (data.addressName) {
    formData.append("addressName", data.addressName);
  }

  // Ensure to include the userId in your request to identify which user to update
  return axios.patch(`/profile/update`, formData);
};
