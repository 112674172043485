import { useState } from "react";
import { Box, Image, Text, Flex, Grid } from "@chakra-ui/react";
import defaultImage from "assets/images/profileDefault.png";
import {
  CustomButton,
  CustomModal,
  FlexContent,
  ViewPopover,
} from "components/shared";
import { Link } from "react-router-dom";
import { FaPhone, FaLocationDot } from "react-icons/fa6";
import { MdOutlineDateRange } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { BiLink } from "react-icons/bi";
import AddClientModal from "./add-client-modal";

const ClientCard = ({
  name,
  phoneNumber,
  createdAt,
  userId,
  data,
  refetch,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Box p="25px" borderRadius="10px" border="2px solid #e7e3e3" bg="white">
      <Flex alignItems="center" mb="8px">
        <Grid
          placeItems="center"
          me="10px"
          w="40px"
          h="40px"
          bg="#f7f7f7"
          border="1px solid #cfcfcf"
          borderRadius="50%">
          <Box as={FaUserAlt} />
        </Grid>
        <Text>
          <Text as="span" fontWeight="bold">
            الاسم :{" "}
          </Text>
          {name}
        </Text>
      </Flex>

      <Flex alignItems="center" mb="8px">
        <Grid
          placeItems="center"
          me="10px"
          w="40px"
          h="40px"
          bg="#f7f7f7"
          border="1px solid #cfcfcf"
          borderRadius="50%">
          <Box as={FaPhone} />
        </Grid>
        <Text>
          <Text as="span" fontWeight="bold">
            الهاتف :{" "}
          </Text>
          {phoneNumber}
        </Text>
      </Flex>

      <Flex alignItems="center" mb="8px">
        <Grid
          placeItems="center"
          me="10px"
          w="40px"
          h="40px"
          bg="#f7f7f7"
          border="1px solid #cfcfcf"
          borderRadius="50%">
          <Box as={MdOutlineDateRange} />
        </Grid>
        <Text>
          <Text as="span" fontWeight="bold">
            تاريخ التسجيل :{" "}
          </Text>
          {createdAt}
        </Text>
      </Flex>

      <Grid gridTemplateColumns="repeat(2,auto)" gap="15px" mt="10px">
        <CustomButton
          bg="bg.mainColor"
          name="تعديل"
          fontSize={["14px", " ", "", "initial"]}
          onClick={(e) => {
            setShowModal(true);
          }}
        />
        <CustomButton
          bg="bg.lightBg"
          hoverColor="bg.hoverLightColor"
          color="black"
          as={Link}
          to={`/client/${userId}`}
          name="رؤية الملف الشخصى"
          fontSize={["14px", " ", "", "initial"]}
        />
      </Grid>

      <CustomModal
        title="تعديل البيانات "
        show={showModal}
        setShow={setShowModal}
        maxW="700px">
        <AddClientModal
          closeModal={setShowModal}
          data={data}
          refetch={refetch}
        />
      </CustomModal>
    </Box>
  );
};

export default ClientCard;
