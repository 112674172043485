import * as Yup from "yup";

const loginValidations = Yup.object().shape({
  email: Yup.string().email("الايميل غير صحيح").required("هذا الحقل مطلوب"),

  password: Yup.string()
    .min(6, "يجب ان تكون عدد الحروف اكتر من 6")
    .max(100, "يجب ان تكون عدد الحروف اقل من 100")
    .required("هذا الحقل مطلوب"),
});

export default loginValidations;
