import { Box } from "@chakra-ui/react";
import Header from "./header";
import Footer from "./footer";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const { pathname } = useLocation();

  if (pathname.includes("/rate")) {
    return (
      <Box display="grid" gridTemplateRows="auto 1fr auto" minH="100vh">
        <Box minH="450px">{children}</Box>
      </Box>
    );
  }
  return (
    <Box display="grid" gridTemplateRows="auto 1fr auto" minH="100vh">
      <Header />
      <Box minH="450px">{children}</Box>
      <Footer />
    </Box>
  );
};

export default Layout;
