import { useContext } from "react";
import { Box, Image } from "@chakra-ui/react";

import { RateForm } from "components/create-rate";
import { useQuery } from "react-query";
import { getReviewTemplate } from "apis/tickets";
import { Loading } from "components/shared";
import blob from "assets/images/vec.svg";
import { AuthContext } from "context/user-auth";

const CreateRate = () => {
  const { authState } = useContext(AuthContext);
  const user = authState?.user;

  const { data, isLoading, refetch } = useQuery(
    ["get review template", user?._id],
    getReviewTemplate
  );

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Box pos="relative" my="50px">
      <Image
        src={blob}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%,-50%)"
        w="700px"
        pointerEvents="none"
        zIndex="-1"
        display={["none", "initial", "", ""]}
      />
      <Box pos="relative" zIndex="111">
        <RateForm data={data?.data} refetch={refetch} isForUpdating />
      </Box>
    </Box>
  );
};

export default CreateRate;
