import { Flex, Image, Heading, Text } from "@chakra-ui/react";
import empty from "assets/images/empty.png";

const Nodata = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mt="40px">
      <Image src={empty} w="220px" m="auto" mb="20px" />
      <Heading
        as="h3"
        fontWeight="bold"
        mb="10px"
        opacity=".6"
        textAlign="center">
        لا يوجد بيانات لعرضها
      </Heading>
    </Flex>
  );
};

export default Nodata;
