import { useState, useContext, useEffect } from "react";
import {
  Box,
  Flex,
  Image,
  UnorderedList,
  ListItem,
  Text,
  Grid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
} from "@chakra-ui/react";
import {
  CustomContainer,
  CustomMenu,
  CustomModal,
  CustomButton,
} from "components/shared";
import logo from "assets/images/logo1.svg";
import { Link, useLocation } from "react-router-dom";
// import { userContext } from "contexts/userContext";
import {
  BiSolidCategory,
  BiFoodMenu,
  BiSolidPurchaseTagAlt,
} from "react-icons/bi";
import {
  FaPeopleGroup,
  FaChartColumn,
  FaBasketShopping,
} from "react-icons/fa6";
import { MdStorefront } from "react-icons/md";
import { IoMdSettings, IoIosArrowDown } from "react-icons/io";
import { IoNotificationsSharp, IoPeople } from "react-icons/io5";
import { FaHandshakeSimple } from "react-icons/fa6";
import { CgProfile } from "react-icons/cg";
import { SlLogout } from "react-icons/sl";
import { BsPersonFillCheck } from "react-icons/bs";
import { AuthContext } from "context/user-auth";
import defaultImage from "assets/images/profileDefault.png";
import { HashLink } from "react-router-hash-link";
// import { NotificationsModal } from "components/notifications";
import ResponsiveHeader from "../responsive-header";

const UnAuthHeader = () => {
  // const { user } = useContext(userContext);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const { pathname } = useLocation();

  const navbarLinks = [
    {
      name: "الصفحة الرئيسية",
      link: "/#home",
      icon: IoPeople,
    },
    {
      name: "مميزتنا ",
      link: "/#features",
      icon: IoPeople,
    },
    { name: " كيف تعمل", link: "/#howitworks", icon: BiFoodMenu },
    { name: " تواصل معنا", link: "/#contactus", icon: BiSolidCategory },
  ];
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      transition="0.3s"
      pos={pathname == "/" ? "fixed" : "relative"}
      zIndex="11"
      boxShadow={
        scrollPosition > 20 && pathname == "/"
          ? " rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          : "none"
      }
      w="100%"
      bg="white"
      py="10px"
      borderBottom="2px solid #e0e0e0"
      borderColor={
        scrollPosition > 20 && pathname == "/" ? "transparent" : "#e0e0e0"
      }>
      <CustomContainer>
        <Flex alignItems="center" justify="space-between">
          <Flex
            maxW="container"
            display={["none", "", "flex", ""]}
            alignItems="center">
            <Link to="/">
              <Image src={logo} alt="Logo" w="90px" me="50px" />
            </Link>

            <UnorderedList
              display="flex"
              listStyleType="none"
              mx={["1em", "1em", "10px", "1em"]}>
              {navbarLinks?.map((e, i) => {
                return (
                  <ListItem
                    me={
                      navbarLinks.length - 1 == i
                        ? "0"
                        : ["20px", "20px", "10px", "20px"]
                    }
                    color="#787878"
                    // color={pathname == e?.link ? "text.mainColor" : "#787878"}
                  >
                    <Box as={HashLink} to={e.link}>
                      <Flex alignItems="center">
                        <Box as={e.icon} me="5px" fontSize="22px" />
                        <Text> {e.name}</Text>
                      </Flex>
                    </Box>
                  </ListItem>
                );
              })}
            </UnorderedList>
          </Flex>

          <Grid
            gridTemplateColumns="repeat(2,1fr)"
            gap="15px"
            display={["none", "", "grid", ""]}>
            <CustomButton as={Link} to="/login" name="تسجيل دخول" />
            <CustomButton
              as={Link}
              to="/signup"
              name="اشترك مجانا "
              bg="#ffbf60"
              hoverColor="#fcbf66"
            />
          </Grid>
        </Flex>
        <ResponsiveHeader
          setShowNotificationModal={setShowNotificationModal}
          navbarLinks={navbarLinks}
          linkType={HashLink}
        />
      </CustomContainer>
      <CustomModal
        title="الاشعارات"
        show={showNotificationModal}
        setShow={setShowNotificationModal}
        maxW="650px">
        {/* <NotificationsModal /> */}
      </CustomModal>
    </Box>
  );
};

export default UnAuthHeader;
