import { useState, useEffect, useContext } from "react";
import { Box, Text, Grid } from "@chakra-ui/react";
import ReviewCard from "components/dashboard/review-card";
import {
  CustomButton,
  CustomContainer,
  CustomDate,
  CustomSelect,
  Loading,
  Nodata,
} from "components/shared";
import { useQuery } from "react-query";
import { getReviews, getReviewTemplate } from "apis/tickets";
import moment from "moment";
import { AuthContext } from "context/user-auth";

const Reviews = () => {
  const [buttonLoading, setButtonLoading] = useState(null);
  const { authState } = useContext(AuthContext);

  const user = authState?.user;

  const starsSelections = [
    {
      name: "الكل",
      id: null,
    },
    {
      name: "نجمة واحدة",
      id: 1,
    },

    {
      name: "نجمتان",
      id: 2,
    },

    {
      name: "ثلاث نجوم",
      id: 3,
    },

    {
      name: "أربع نجوم",
      id: 4,
    },
    {
      name: "خمس نجوم",
      id: 5,
    },
  ];
  let itemsPerPage = 12;
  const [page, setPage] = useState(0);
  const [starsCount, setStarsCount] = useState(starsSelections[0]);
  const [question, setQuestion] = useState({
    name: "",
    id: "",
  });

  const [enterDate, setEnterDate] = useState(
    moment(new Date()).format("DD-MM-yyyy")
  );

  const handleStarsCountSelected = (value) => {
    setStarsCount(value);
  };

  const handleQuestionSelected = (value) => {
    setQuestion(value);
  };

  const { data: questionsData, isLoading: isLoadingQuestions } = useQuery(
    ["get review template", user?._id],
    getReviewTemplate
  );

  const questionsList = questionsData?.data?.questions?.map((e) => {
    return { name: e?.serviceName, id: e?._id };
  });

  const { data, isLoading, refetch } = useQuery(
    [
      "get reviews",
      {
        date: enterDate && enterDate,
        starsCount: starsCount?.id,
        question: question?.id,
        page,
        itemsPerPage,
      },
    ],
    getReviews
  );

  // if (isLoading) {
  //   return <Loading />;
  // }

  useEffect(() => {
    if (questionsList?.length >= 1) {
      setQuestion(questionsList[0]);
    }
  }, [questionsData?.data]);

  useEffect(() => {
    if (questionsList?.length >= 1) {
      setQuestion(questionsList[0]);
    }
  }, []);

  const loadNext = () => {
    if ((page + 1) * itemsPerPage <= data?.data?.total) {
      setPage(page + 1);
      setButtonLoading("next");
    }
  };

  const loadPrev = () => {
    if (page > 0) {
      setPage(page - 1);
      setButtonLoading("prev");
    }
  };

  useEffect(() => {
    setPage(0);
  }, [question, enterDate, starsCount]);
  return (
    <Box my="50px">
      <CustomContainer>
        <Text
          fontWeight="bold"
          fontSize={["25px", "45px", "45px", "45px"]}
          textAlign="center"
          textTransform="capitalize"
          mb="30px">
          جميع التقيمات
        </Text>
        <Grid
          gridTemplateColumns={[
            "repeat(1,1fr)",
            "repeat(3,1fr)",
            "repeat(3,1fr)",
            "repeat(3,1fr)",
          ]}
          w={["100%", "80%", "60%", "50%"]}
          gap="15px"
          mb="20px">
          <Box>
            <Box mb="5px" color="gray" fontSize={["14px", "16px", "", ""]}>
              تاريخ التقيم
            </Box>
            <CustomDate
              buttonH="50px"
              placeholder="حدد تاريخ التقيم"
              handleDate={(value) => setEnterDate(value)}
              fontSize={["14px", "initial", "", ""]}
              defaultDate={new Date()}
            />
          </Box>

          <CustomSelect
            label="نوع الخدمة"
            select={question}
            selections={questionsList}
            handleSelect={handleQuestionSelected}
            placeholder="اختر  السؤال"
            h="50px"
            px="25px"
            // w={["100%", "100%", "initial", ""]}
            fontSize={["14px", "initial", "", ""]}
          />

          <CustomSelect
            label="عدد النجوم"
            select={starsCount}
            selections={starsSelections}
            handleSelect={handleStarsCountSelected}
            placeholder="اختر تقيم هذ السؤال"
            h="50px"
            px="25px"
            // w={["100%", "100%", "initial", ""]}
            fontSize={["14px", "initial", "", ""]}
          />
        </Grid>
        {data?.data.total == 0 ? <Nodata /> : null}

        {isLoading ? (
          <Loading />
        ) : (
          <Grid
            gridTemplateColumns={[
              "repeat(1,1fr)",
              "repeat(2,1fr)",
              "repeat(2,1fr)",
              "repeat(4,1fr)",
            ]}
            gap="20px"
            mb="30px">
            {data?.data?.reviews?.map((r) => {
              return (
                <ReviewCard
                  name={r?.authorName}
                  date={r?.createdAt}
                  notes={
                    r?.questions?.find((e) => e._id == question?.id)?.notes
                  }
                  rating={
                    r?.questions?.find((e) => e._id == question?.id)?.rating
                  }
                  reviews={r?.questions}
                  noDetails
                  showText
                />
              );
            })}
          </Grid>
        )}
        {isLoading ||
        (page == 0 && itemsPerPage >= data?.data?.total) ? null : (
          <Grid
            gridTemplateColumns="repeat(2,1fr)"
            gap="20px"
            w={["90%", "500px", "500px", "500px"]}
            m="auto"
            mt="40px">
            <CustomButton
              name="التالى"
              loading={isLoading}
              onClick={loadNext}
              isDisabled={(page + 1) * itemsPerPage >= data?.data?.total}
            />

            <CustomButton
              name="السابق"
              loading={isLoading}
              onClick={loadPrev}
              isDisabled={page == 0}
            />
          </Grid>
        )}
      </CustomContainer>
    </Box>
  );
};

export default Reviews;
