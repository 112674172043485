import { CustomButton } from "components/shared";
import { IoCloudDownloadSharp } from "react-icons/io5";

const ImageDownloader = ({ base64Image }) => {
  // Create a download link for the base64 image
  const downloadImage = () => {
    // Create an anchor element
    const link = document.createElement("a");

    // Set the href attribute to the base64 image data
    link.href = base64Image;

    // Set the download attribute to specify the file name
    link.download = "qr.png"; // Change the filename and extension as needed

    // Programmatically click the link to trigger the download
    link.click();
  };

  return (
    <CustomButton
      name="  ال qr الخاص بك"
      icon={IoCloudDownloadSharp}
      w={["50%", "200px", "200px", "200px"]}
      m="0"
      mt="15px"
      onClick={downloadImage}
    />
  );
};

export default ImageDownloader;
