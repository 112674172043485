import { useContext } from "react";
import { Box, Text, Grid, useToast } from "@chakra-ui/react";
import {
  CustomInput,
  CustomUploadFile,
  ErrorMsg,
  CustomToast,
  CustomButton,
} from "components/shared";
import { Link } from "react-router-dom";
import avatar from "assets/images/profileDefault.png";
import { Formik, Form } from "formik";
import { useSwiper } from "swiper/react";
import { useMutation } from "react-query";
import { signup } from "apis/tickets";
import { AuthContext } from "context/user-auth";
import signupProjectValidations from "validations/singup-project";

const PlaceInfoForm = ({ personalInfo }) => {
  const { setAuthState } = useContext(AuthContext);
  const swiper = useSwiper();

  const toast = useToast();

  const { mutate, isLoading } = useMutation(signup, {
    onSuccess: (res) => {
      localStorage.setItem("token", res?.data?.token);

      setAuthState({
        isAuthenticated: true,
        user: res?.data.user,
        token: res?.data.token,
      });
    },
    onError: (err) => {
      toast({
        render: (props) => {
          return (
            <CustomToast
              title={"حدث خطأ"}
              description={err?.response?.data?.errors?.alreadyExist}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  return (
    <Formik
      initialValues={{
        profileImg: "",
        projectName: "",
        addressName: "",
      }}
      validationSchema={signupProjectValidations}
      onSubmit={(values) => {
        mutate({ ...personalInfo, ...values });
        //console.log({ ...personalInfo, ...values });
      }}>
      {(formik) => {
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Text fontWeight="bold" mb="20px" fontSize="26px" color="#383838">
              التسجيل
            </Text>
            <Text mb="20px" color="#a2a2a2">
              سجل الأن واستمتع بجميع الخدمات التى نقدمها او كن شريك لنا واعمل
              معنا وحقق الربح الذى تريده
            </Text>

            <Box mb="20px">
              <CustomUploadFile
                radius={false}
                defaultImage={formik.values.profileImg || avatar}
                formikChange={(value) =>
                  formik.setFieldValue("profileImg", value)
                }
              />
            </Box>
            <Box mb="20px">
              <CustomInput
                placeholder="اسم مشروعك"
                onChange={formik.handleChange("projectName")}
                onBlur={formik.handleBlur("projectName")}
                value={formik.values.projectName}
              />
              {formik.errors.projectName && formik.touched.projectName && (
                <ErrorMsg text={formik.errors.projectName} />
              )}
            </Box>

            <Box mb="20px">
              <CustomInput
                placeholder=" العنوان"
                onChange={formik.handleChange("addressName")}
                onBlur={formik.handleBlur("addressName")}
                value={formik.values.addressName}
              />
              {formik.errors.addressName && formik.touched.addressName && (
                <ErrorMsg text={formik.errors.addressName} />
              )}
            </Box>

            <Grid gridTemplateColumns="repeat(2,1fr)" gap="20px">
              <CustomButton
                type="submit"
                mt="25px"
                mb="5px"
                name="السابق"
                onClick={() => swiper.slidePrev()}
              />

              <CustomButton
                type="submit"
                mt="25px"
                mb="5px"
                name="حفظ"
                loading={isLoading}
                onClick={() => swiper.slideNext()}
              />
            </Grid>

            <Text textAlign="center">
              هل لديك حساب بالفعل؟{" "}
              <Text
                as={Link}
                to="/login"
                fontWeight="bold"
                color="text.mainColor">
                سجل دخول الأن
              </Text>
            </Text>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PlaceInfoForm;
