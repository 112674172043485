import { Box, Text } from "@chakra-ui/react";
import { CustomButton } from "components/shared";
import t1 from "assets/images/t2.png";
import { Link } from "react-router-dom";

const CallToAction = () => {
  return (
    <Box
      bg={`url(${t1}) center no-repeat`}
      backgroundSize="cover"
      color="white"
      py="50px"
      my="60px">
      <Text
        textAlign="center"
        fontSize={["25px", "28px", "30px", "30px"]}
        fontWeight="bold"
        mb="20px">
        {/* ابدأ الآن واحصل على تحكم كامل! */}
        اشترك اليوم وحقق نموًا في أرباحك!
      </Text>
      <Text
        fontSize={["14px", "16px", " ", " "]}
        textAlign="center"
        mb="20px"
        w={["90%", "80%", "40%", "40%"]}
        mx="auto">
        {/* سجّل اليوم وكن من بين الرواد في تحسين تجربة عملائك. احصل على جميع
        الأدوات اللازمة لإدارة التقييمات بفعالية وبسعر مناسب. لا تفوت الفرصة! */}
        استفد من منصتنا لتحسين تجربة العملاء وزيادة ولائهم. سجل اليوم لتحصل على
        أدوات فعالة لإدارة التقييمات وتعزيز جودة خدماتك، مما يساهم في زيادة
        مبيعاتك وأرباحك.
      </Text>
      <CustomButton as={Link} to="/signup" name="اشترك الان" w="180px" />
    </Box>
  );
};

export default CallToAction;
