import { useState, useContext, useEffect } from "react";
import {
  Box,
  Flex,
  Image,
  UnorderedList,
  ListItem,
  Text,
  Grid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
} from "@chakra-ui/react";
import {
  CustomContainer,
  CustomMenu,
  CustomModal,
  CustomButton,
} from "components/shared";
import logo from "assets/images/logo1.svg";
import { Link, useLocation } from "react-router-dom";
// import { userContext } from "contexts/userContext";
import {
  BiSolidCategory,
  BiFoodMenu,
  BiSolidPurchaseTagAlt,
} from "react-icons/bi";
import {
  FaPeopleGroup,
  FaChartColumn,
  FaBasketShopping,
} from "react-icons/fa6";
import { MdDashboard, MdReviews } from "react-icons/md";
import { IoMdSettings, IoIosArrowDown } from "react-icons/io";
import { IoNotificationsSharp, IoPeople } from "react-icons/io5";
import { FaWpforms } from "react-icons/fa6";
import { CgProfile } from "react-icons/cg";
import { SlLogout } from "react-icons/sl";
import { BsQrCode } from "react-icons/bs";

import { AuthContext } from "context/user-auth";
import defaultImage from "assets/images/profileDefault.png";
// import { NotificationsModal } from "components/notifications";
import ResponsiveHeader from "../responsive-header";

const AuthHeader = () => {
  const { logout, authState } = useContext(AuthContext);
  // const { user } = useContext(userContext);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const { pathname } = useLocation();

  const navbarLinks = [
    { name: "لوحة التحكم  ", link: "/", icon: MdDashboard },
    { name: " التقيمات", link: "/reviews", icon: MdReviews },
    { name: " العملاء", link: "/clients", icon: IoPeople },
    { name: "تعديل نموذج التقيم", link: "/createRate", icon: FaWpforms },
    { name: "qr code", link: "/qr", icon: BsQrCode },
  ];

  return (
    <Box bg="white" py="10px" borderBottom="2px solid #e0e0e0">
      <CustomContainer>
        <Flex alignItems="center" justifyContent="space-between">
          <Flex display={["none", "", "flex", ""]} alignItems="center">
            <Link to="/">
              <Image
                src={logo}
                alt="Logo"
                w="90px"
                me={["50px", "50px", "20px", "50px"]}
              />
            </Link>

            <UnorderedList
              display="flex"
              listStyleType="none"
              mx={["1em", "1em", "10px", "1em"]}>
              {navbarLinks?.map((e, i) => {
                return (
                  <ListItem
                    me={
                      navbarLinks.length - 1 == i
                        ? "0"
                        : ["20px", "20px", "10px", "20px"]
                    }
                    color={pathname == e?.link ? "text.mainColor" : "#787878"}>
                    <Link to={e.link}>
                      <Flex alignItems="center">
                        <Box as={e.icon} me="5px" fontSize="22px" />
                        <Text fontSize={["initial", "", "14px", "initial"]}>
                          {" "}
                          {e.name}
                        </Text>
                      </Flex>
                    </Link>
                  </ListItem>
                );
              })}
            </UnorderedList>
          </Flex>
          <Flex
            alignItems="center"
            gap={["15px", "15px", "10px", "15px"]}
            display={["none", "", "flex", ""]}>
            <CustomButton
              bg="bg.lightBg"
              hoverColor="bg.hoverLightColor"
              color="black"
              name=" الملف الشخصى"
              icon={CgProfile}
              as={Link}
              to="/profile"
              w="auto"
              m="0"
              fontSize={["initial", "", "14px", "initial"]}
            />
            <CustomButton
              bg="bg.thirdColor"
              hoverColor="bg.hoverThirdColor"
              name="خروج"
              icon={SlLogout}
              onClick={logout}
              w="auto"
              m="0"
              fontSize={["initial", "", "14px", "initial"]}
            />
          </Flex>
        </Flex>
        <ResponsiveHeader
          setShowNotificationModal={setShowNotificationModal}
          linkType={Link}
          isAuth
          logout={logout}
          navbarLinks={[
            { name: "الملف الشخصى", link: "/profile", icon: CgProfile },
            ...navbarLinks,
          ]}
        />
      </CustomContainer>
      <CustomModal
        title="الاشعارات"
        show={showNotificationModal}
        setShow={setShowNotificationModal}
        maxW="650px">
        {/* <NotificationsModal /> */}
      </CustomModal>
    </Box>
  );
};

export default AuthHeader;
