import axios from "axios";

export const configureAxios = (accessToken = null, language = null) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  if (language)
    axios.defaults.headers.common["Accept-Language"] = `${language}`;
  if (accessToken)
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  axios.defaults.headers.post["Content-Type"] = "application/json";
};
