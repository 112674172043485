import { Box } from "@chakra-ui/react";

const CustomContainer = ({ children, ...props }) => {
  return (
    <Box maxW="1440px" m="auto" px="15px" {...props}>
      {children}
    </Box>
  );
};

export default CustomContainer;
