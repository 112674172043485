import { useState } from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import DetailsModals from "components/reviews/details-modal";
import { CustomModal } from "components/shared";
import { IoStar } from "react-icons/io5";

const Stars = ({ numReview, ...props }) => {
  const Star = ({ starColor }) => {
    return <Box as={IoStar} fontSize="20px" color={starColor} />;
  };

  return (
    <Flex {...props}>
      {Array.from({ length: 5 }).map((e, i) => {
        return <Star starColor={i < numReview ? "gold" : "#b8b8b8"} />;
      })}
    </Flex>
  );
};

const ReviewCard = ({
  name,
  date,
  notes,
  rating,
  reviews,
  noDetails,
  showText,
  ...props
}) => {
  const [showDetials, setShowDetails] = useState(false);

  return (
    <Box
      p="15px"
      boxShadow="rgba(0, 0, 0, 0.16) 0px 1px 4px"
      borderRadius="10px"
      // border="1px solid #e0e0e0"
      // bg="#f7f7f8"
      {...props}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontWeight="bold">{name}</Text>
        <Text
          as="span"
          bg="bg.mainColor"
          color="white"
          px="10px"
          borderRadius="5px">
          {date}
        </Text>
      </Flex>

      <Stars mt="5px" mb="20px" numReview={rating} />

      <Text display="flex" alignItems="center" gap="10px">
        <Text
          as="span"
          overflow={showText ? "initial" : "hidden"}
          maxW={showText ? "inital" : "250px"}
          textOverflow="ellipsis"
          display="inline-block"
          whiteSpace={showText ? "wrap" : "nowrap"}>
          {notes ? notes : "لم يتم كتابة اى ملاحظات"}
        </Text>{" "}
        {noDetails || reviews?.length == 1 ? null : (
          <Text
            as="span"
            cursor="pointer"
            onClick={() => setShowDetails(true)}
            textDecor="underline">
            تفاصيل اكثر
          </Text>
        )}
      </Text>

      {noDetails || reviews?.length == 1 ? null : (
        <>
          <CustomModal
            title={`تقيم بواسطة ${name} `}
            show={showDetials}
            setShow={setShowDetails}>
            <DetailsModals reviews={reviews} />
          </CustomModal>
        </>
      )}
    </Box>
  );
};

export default ReviewCard;
