import { Input, Text } from "@chakra-ui/react";

const CustomInput = ({ label, ...props }) => {
  return (
    <>
      {label && (
        <Text mb="5px" color="gray">
          {label}
        </Text>
      )}
      <Input py="25px" _focus={{ boxShadow: "none" }} _active={{}} {...props} />
    </>
  );
};

export default CustomInput;
