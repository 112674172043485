import { useState } from "react";
import { Box, Flex, Text, Grid, Image } from "@chakra-ui/react";
import signupBg from "assets/images/signup-white.svg";
import Slider from "components/signup/slider.js";
import { SignupPersonalForm, PlaceInfoForm } from "components/signup";

const Signup = () => {
  const [personalInfo, setPersonalInfo] = useState();
  const [placeInfo, setPlaceInfo] = useState();

  return (
    <>
      <Grid minH="100vh" my="50px">
        <Box
          bg="white"
          borderRadius="5px"
          overflow="hidden"
          w={["90%", "90%", "80%", "65%"]}
          m="auto"
          boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"

          //border="4px solid #d7a73c"
        >
          <Flex flexDir={["column", "row", "", ""]}>
            <Grid
              p="50px"
              bg="#f4f4f4"
              w={["100%", "50%", "50%", ""]}
              placeItems="center">
              <Box>
                <Image
                  src={signupBg}
                  m="auto"
                  mb="15px"
                  w={["90%", "100%", "100%", "100%"]}
                />
                {/* <Text
                textAlign="center"
                fontSize="25px"
                fontWeight="bold"
                color="white">
                {" "}
                حرفى
              </Text> */}
                <Text textAlign="center" fontSize="30px">
                  مجموعة من الخدمات صممت من اجلك
                </Text>
              </Box>
              {/* <lottie-player
            src="https://assets9.lottiefiles.com/packages/lf20_gzl797gs.json"
            background="transparent"
            speed="1"
            w="100%"
            h="300px"
            loop
            autoplay
          /> */}
            </Grid>
            <Box
              p={["15px", "43px", "43px", "43px"]}
              w={["100%", "50%", "50%", ""]}>
              <Slider
                forms={[
                  <SignupPersonalForm setPersonalInfo={setPersonalInfo} />,
                  <PlaceInfoForm
                    personalInfo={personalInfo}
                    placeInfo={placeInfo}
                  />,
                ]}
              />
            </Box>
          </Flex>
        </Box>
      </Grid>
    </>
  );
};

export default Signup;
