import { Box } from "@chakra-ui/react";

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

ChartJS.defaults.font.family = "cairo";

export const options = {
  responsive: true,
  maintainAspectRatio: false,

  plugins: {
    legend: {
      display: true,
      position: "top",
      align: "end",
    },
    title: {
      display: false,
      text: "المبيعات والمشتريات",
    },
    datalabels: {
      color: "#ffffff",
      display: false,
      //   anchor: "end",
      align: "center",
      font: { size: 18 },
      textAlign: "center",
      formatter: (value, context) => {
        return `${value}$`;
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    // y: {
    //   grid: {
    //     display: false,
    //   },
    // },
  },
};

const labels = [
  "يناير",
  "فبراير",
  "مارس",
  "إبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];

export const data = {
  labels,
  datasets: [
    {
      label: "المبيعات",
      data: [
        20000, 30000, 100000, 50000, 11100, 18000, 20000, 30000, 8524, 7892,
        6547, 18000,
      ],
      backgroundColor: "#1c536b",
      borderRadius: 5,
    },
    {
      label: "المشتريات",
      data: [
        2000, 200, 50000, 54675, 7589, 60000, 5528, 638, 71817, 2447, 7895, 357,
      ],
      backgroundColor: "#a2e0f0",
      borderRadius: 5,
    },
  ],
};

const ChartSales = () => {
  return <Bar options={options} data={data} />;
};

export default ChartSales;
