import { useState, useRef } from "react";
import {
  Box,
  Flex,
  Grid,
  Image,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  ListItem,
  UnorderedList,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { IoNotificationsSharp, IoPeople } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import logo from "assets/images/logo1.svg";
import { IoMenu } from "react-icons/io5";
import { IoMdSettings, IoIosArrowDown } from "react-icons/io";
import { CustomButton } from "components/shared";
import { SlLogout } from "react-icons/sl";

const ResponsiveHeader = ({
  navbarLinks,
  setShowNotificationModal,
  linkType,
  isAuth,
  logout,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const { pathname } = useLocation();

  return (
    <Box display={["block", "", "none", ""]}>
      <Flex justifyContent="space-between" alignItems="center">
        <Link to="/">
          <Image src={logo} alt="Logo" w="90px" />
        </Link>

        <Flex alignItems="center">
          <Box
            as={IoMenu}
            fontSize="35px"
            ms="8px"
            cursor="pointer"
            ref={btnRef}
            colorScheme="teal"
            onClick={onOpen}
          />
        </Flex>
      </Flex>
      <>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton
              left="15px"
              top="22px"
              fontSize="16px"
              right="initial"
              _hover={{}}
              _active={{}}
              _focusVisible={{}}
            />
            <DrawerHeader>
              <Link to="/" onClick={onClose}>
                <Image src={logo} alt="Logo" w="120px" />
              </Link>
            </DrawerHeader>

            <DrawerBody>
              <UnorderedList listStyleType="none">
                {navbarLinks?.map((e, i) => {
                  return (
                    <ListItem
                      mb={navbarLinks.length - 1 == i ? "0" : "40px"}
                      color={
                        pathname == e?.link ? "text.mainColor" : "#787878"
                      }>
                      {e?.subLinks ? (
                        <Accordion allowMultiple>
                          <AccordionItem border="none">
                            <h2>
                              <AccordionButton p="0" _hover={{}}>
                                <Flex
                                  justifyContent="space-between"
                                  alignItems="center"
                                  w="100%">
                                  <Flex alignItems="center">
                                    <Box
                                      as={e?.icon}
                                      me="5px"
                                      fontSize="22px"
                                    />
                                    <Text>{e?.name} </Text>
                                  </Flex>
                                  <AccordionIcon />
                                </Flex>
                              </AccordionButton>
                            </h2>
                            <AccordionPanel ps="15px" py="20px" bg="whitesmoke">
                              {e?.subLinks?.map((s, index) => {
                                return (
                                  <ListItem
                                    mb={
                                      e?.subLinks.length - 1 == index
                                        ? "0"
                                        : "20px"
                                    }
                                    color={
                                      pathname == s?.link ||
                                      pathname.includes(s?.link)
                                        ? "text.mainColor"
                                        : "#787878"
                                    }>
                                    <Box
                                      as={linkType}
                                      to={s.link}
                                      onClick={onClose}>
                                      <Flex alignItems="center">
                                        <Box
                                          as={s.icon}
                                          me="5px"
                                          fontSize="22px"
                                        />
                                        <Text> {s.name}</Text>
                                      </Flex>
                                    </Box>
                                  </ListItem>
                                );
                              })}
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      ) : (
                        // <CustomMenu matchWidth subLinks={e?.subLinks}>
                        //   <Flex alignItems="center">
                        //     <Box as={e?.icon} me="5px" fontSize="22px" />
                        //     <Text>{e?.name} </Text>
                        //     <Box as={IoIosArrowDown} ms="5px" />
                        //   </Flex>
                        // </CustomMenu>
                        <Box as={linkType} to={e.link} onClick={onClose}>
                          <Flex alignItems="center">
                            <Box as={e.icon} me="5px" fontSize="22px" />
                            <Text> {e.name}</Text>
                          </Flex>
                        </Box>
                      )}
                    </ListItem>
                  );
                })}
              </UnorderedList>
              {isAuth ? (
                <Box
                  mt="40px"
                  color="#787878"
                  ms="20px"
                  cursor="pointer"
                  onClick={() => {
                    logout();
                    onClose();
                  }}>
                  <Flex alignItems="center">
                    <Box as={SlLogout} me="5px" fontSize="22px" />
                    <Text> تسجيل الخروج</Text>
                  </Flex>
                </Box>
              ) : (
                <Grid gridTemplateColumns="repeat(1,1fr)" gap="15px" mt="25px">
                  <CustomButton
                    as={Link}
                    to="/login"
                    name="تسجيل دخول"
                    onClick={onClose}
                  />
                  <CustomButton
                    as={Link}
                    to="/signup"
                    name="اشترك مجانا "
                    bg="#ffbf60"
                    hoverColor="#fcbf66"
                    onClick={onClose}
                  />
                </Grid>
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    </Box>
  );
};

export default ResponsiveHeader;
