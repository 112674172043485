import { Box, Flex, Grid, Text, Image } from "@chakra-ui/react";
import mobileReview from "assets/images/phone-review.png";
import { CustomContainer, CustomButton } from "components/shared";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <Box
      bg="#f7f7f8"
      mt={["80px", "80px", "80px", "80px"]}
      pb={["20px", "", "0", ""]}
      id="home">
      <CustomContainer>
        <Grid
          gridTemplateColumns={[
            "repeat(1,1fr)",
            "repeat(1,1fr)",
            "repeat(2,1fr)",
            "repeat(2,1fr)",
          ]}>
          <Flex
            flexDir="column"
            justifyContent="center"
            order={["2", "2", "1", "1"]}>
            <Box w={["80%", "80%", "80%", "80%"]} m={["auto", "", "0", ""]}>
              <Text
                textAlign={["center", "", "initial", ""]}
                fontSize={["30px", "35px", "45px", "60px"]}
                fontWeight="bold"
                lineHight="10px"
                mb="15px">
                اعرف رأي عملائك وحسن خدماتك مع رأي
              </Text>
              <Text
                textAlign={["center", "", "initial", ""]}
                fontSize={["14px", "16px", "", ""]}>
                اجمع آراء عملائك بكل سهولة، وحللها لتحسين خدماتك وزيادة رضا
                عملائك. جرب رأي مجانًا لأول شهر!
              </Text>
              <CustomButton
                as={Link}
                to="/signup"
                name="اشترك الأن"
                w="150px"
                mx={["auto", "", "0", ""]}
                mt="15px"
              />
            </Box>
          </Flex>

          <Image
            src={mobileReview}
            order={["1", "1", "2", "2"]}
            w={["80%", "60%", "initial", ""]}
            m={["auto", "", "0", ""]}
          />
        </Grid>
      </CustomContainer>
    </Box>
  );
};

export default Hero;
