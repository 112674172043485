import * as Yup from "yup";

const resetPasswordValidations = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, "يجب ان تكون عدد الحروف اكتر من 6")
    .max(100, "يجب ان تكون عدد الحروف اقل من 100")
    .required("هذا الحقل مطلوب"),
  confirmPassword: Yup.string()
    .min(6, "يجب ان تكون عدد الحروف اكتر من 6")
    .max(100, "يجب ان تكون عدد الحروف اقل من 100")
    .required("هذا الحقل مطلوب")
    .oneOf([Yup.ref("newPassword"), null], "الرقم السري غير متطابق"),
});

export default resetPasswordValidations;
