import { Box, Grid, Text, Flex, Image } from "@chakra-ui/react";
import { CustomContainer } from "components/shared";
import sign from "assets/images/sign3.svg";
import form from "assets/images/form3.svg";
import dashboard from "assets/images/dashboard3.svg";
import SectionHeader from "./section-header";

const FlexRow = ({ img, title, desc, step, dir }) => {
  return (
    <Flex
      dir={dir}
      gap={dir ? "0" : "50px"}
      flexWrap={["wrap", "", "nowrap", ""]}
      flexDir={["column", "", "row", ""]}
      alignItems="center"
      mb={["50px", "", "50px", "20px"]}>
      <Image
        src={img}
        ms={["0", "", dir == "ltr" ? "auto" : "0", ""]}
        w={["60%", "40%", "initial", ""]}
      />
      <Grid placeItems="center" w={["100%", "", "48%", "48%"]}>
        <Box dir="rtl">
          <Text
            fontSize={["14px", "16px", "", ""]}
            as="span"
            bg="bg.mainColor"
            color="white"
            px="10px"
            borderRadius="5px">
            الخطوة {step}
          </Text>
          <Text
            fontSize={["22px", "30px", "30px", "30px"]}
            fontWeight="bold"
            mt="5px"
            mb="15px">
            {title}
          </Text>
          <Text w="90%" fontSize={["14px", "16px", "", ""]}>
            {desc}
          </Text>
        </Box>
      </Grid>
    </Flex>
  );
};

const HowItWorks = () => {
  const steps = [
    {
      img: sign,
      step: "1",
      title: "سجّل في المنصة",
      desc: "قم بالتسجيل في منصتنا مقابل 150 جنيهًا، واستفد من جميع الميزات التي نقدمها لتسهيل عملية جمع التقييمات.",
    },
    {
      img: form,
      step: "2",
      title: "خصص أسئلتك",
      desc: "استخدم لوحة التحكم لتصميم الأسئلة التي تود طرحها على عملائك. اختر الأسئلة التي تعكس جودة خدماتك بشكل دقيق.",
    },
    {
      img: dashboard,
      step: "3",
      title: "استقبل التقييمات وحلل النتائج",
      desc: "امنح عملائك فرصة لتقديم تقييماتهم عبر QR، ثم استخدم منصتنا لتحليل النتائج واتخاذ قرارات لتحسين خدماتك.",
    },
  ];

  return (
    <Box mt="100px" id="howitworks">
      <SectionHeader
        title="ازاى تستخدم منصة رأى؟"
        desc="اكتشف كيفية استخدام رأي لتحسين خدماتك. اتبع الخطوات البسيطة لجمع تقييمات العملاء وتحليلها بكل سهولة."
      />

      <CustomContainer>
        <Box w={["100%", "100%", "80%", "80%"]} m="auto">
          {steps.map((e, i) => {
            return (
              <FlexRow
                img={e?.img}
                step={e?.step}
                title={e?.title}
                desc={e?.desc}
                dir={i == 1 && "ltr"}
              />
            );
          })}
        </Box>
      </CustomContainer>
    </Box>
  );
};

export default HowItWorks;
