import { Text, Box, Grid } from "@chakra-ui/react";
import { CustomContainer } from "components/shared";

import { MdRateReview } from "react-icons/md";
import { BiSolidMobileVibration } from "react-icons/bi";

import { FaUsers } from "react-icons/fa";
import SectionHeader from "./section-header";
import { IoAnalyticsOutline } from "react-icons/io5";

const FeatureCard = ({ icon, title, desc }) => {
  return (
    <Box
      p={["20px", "40px", "25px", "50px"]}
      bg="#f7f7f8"
      borderRadius="10px"
      border="2px solid #e0e0e0">
      <Grid
        w={["60px", "80px", "70px", "80px"]}
        h={["60px", "80px", "70px", "80px"]}
        placeItems="center"
        bg="bg.mainColor"
        color="white"
        mb="30px"
        borderRadius="50%">
        <Box as={icon} fontSize={["20px", "30px", "25px", "30px"]} />
      </Grid>
      <Text
        mb="20px"
        fontWeight="bold"
        fontSize={["18px", "20px", "19px", "20px"]}>
        {title}
      </Text>
      <Text fontSize={["14px", "16px", " 15px", " 16px"]}>{desc}</Text>
    </Box>
  );
};
const Features = () => {
  return (
    <Box mt="80px" id="features" px={["20px", "0", "0", ""]}>
      <CustomContainer>
        <SectionHeader
          title="اكتشف مميزات رأى المتقدمة"
          desc="اكتشف الميزات الرائعة التي تجعل رأي الخيار الأمثل لجمع وتحليل آراء العملاء، لتحسين خدماتك وزيادة رضاهم."
        />

        <Grid
          gridTemplateColumns={[
            "repeat(1,1fr)",
            "repeat(2,1fr)",
            "repeat(4,1fr)",
            "repeat(4,1fr)",
          ]}
          gap="15px">
          <FeatureCard
            icon={MdRateReview}
            title="تحكم كامل في الأسئلة"
            desc="قم بتخصيص الأسئلة التي تود طرحها على عملائك بسهولة، واحصل على تقييمات دقيقة تعكس تجربة كل عميل."
          />
          <FeatureCard
            icon={IoAnalyticsOutline}
            title="تحليل التقييمات"
            desc="احصل على تحليل دقيق لتقييمات العملاء يساعدك على فهم احتياجاتهم واتجاهاتهم بشكل أفضل."
          />
          <FeatureCard
            icon={FaUsers}
            title="تخزين آمن للبيانات"
            desc="حافظ على بيانات عملائك بطريقة آمنة ومنظمة، مع وصول سهل إليها لتحسين خدماتك."
          />
          <FeatureCard
            icon={BiSolidMobileVibration}
            title="سهولة الاستخدام عبر الهاتف"
            desc="استخدم رأي من أي مكان، في أي وقت، مباشرة من هاتفك. جمع وتحليل تقييمات العملاء لم يكن أسهل من ذلك!"
          />
        </Grid>
      </CustomContainer>
    </Box>
  );
};

export default Features;
