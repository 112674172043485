import { Box } from "@chakra-ui/react";
import { NewPasswordForm } from "components/new-password";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  const { token } = useParams();

  return (
    <Box
      w={["90%", "90%", "50%", "50%"]}
      my="50px"
      mx="auto"
      p={["30px", "45px", "50px", "50px"]}
      borderRadius="10px"
      border="2px solid #e0e0e0">
      <NewPasswordForm token={token} />
    </Box>
  );
};

export default ResetPassword;
