import { Box, Image, Flex, Text } from "@chakra-ui/react";
import { RateForm } from "components/create-rate";
import { useQuery } from "react-query";
import { getReviewTemplate } from "apis/tickets";
import { Loading } from "components/shared";
import { useParams, useNavigate } from "react-router-dom";
import blob from "assets/images/vec.svg";
import logo from "assets/images/logo1.svg";
import { Link } from "react-router-dom";

const Rate = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useQuery(
    ["get review template", userId],
    getReviewTemplate,
    {
      onError: () => {
        navigate("/");
      },
    }
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box pos="relative" my="20px">
      <Image
        src={blob}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%,-50%)"
        w="700px"
        pointerEvents="none"
        zIndex="-1"
        display={["none", "initial", "", ""]}
      />
      <Box>
        <RateForm data={data?.data} refetch={refetch} userId={userId} />
        <Flex mt="10px" alignItems="flex-end" justifyContent="center">
          <Link to="/">
            <Image src={logo} w="50px" />
          </Link>
          <Text fontSize="12px" ms="5px" fontWeight="bold" color="#3a556a">
            Powerd By
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default Rate;
