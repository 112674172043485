import { useContext } from "react";
import { Box, Grid } from "@chakra-ui/react";
import Layout from "layout";
import {
  LandingPage,
  Dashboard,
  Reviews,
  Clients,
  Client,
  Rate,
  CreateRate,
  Qr,
  Signup,
  ForgetPassword,
  ResetPassword,
  Profile,
} from "pages";
import { AuthContext } from "context/user-auth";
import { Routes, Route, Navigate } from "react-router-dom";
import { configureAxios } from "./axios-config";
import Login from "pages/login";
import { Loading } from "components/shared";

const App = () => {
  const { authState } = useContext(AuthContext);
  const token = localStorage.getItem("token");

  configureAxios(token);

  if (authState.token && authState.user == null) {
    return (
      <Grid placeItems="center" minH="100vh">
        <Loading />
      </Grid>
    );
  }

  return (
    <Layout>
      {authState.isAuthenticated ? (
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/qr" element={<Qr />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/client/:id" element={<Client />} />
          <Route path="/rate/:userId" element={<Rate />} />
          <Route path="/createRate" element={<CreateRate />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/rate/:userId" element={<Rate />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      )}
    </Layout>

    // <Routes>
    //   <Route path="/home" element={<LandingPage />} />
    //   <Route path="/signup" element={<Signup />} />
    //   <Route path="/signin" element={<Signin />} />
    //   <Route path="/review" element={<Review />} />

    //   <Route path="*" element={<Navigate to="/signup" />} />
    // </Routes>
  );
};

export default App;
