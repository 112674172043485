import { Text, Box, Flex, Grid } from "@chakra-ui/react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

// ChartJS.defaults.font.family = "cairo";

export const options = {
  plugins: {
    legend: {
      display: false,
      position: "bottom",
    },
    datalabels: {
      color: "#ffffff",
      //   anchor: "end",
      align: "center",
      font: { size: 14 },
      textAlign: "center",
      formatter: (value, context) => {
        const dataPoints = context.chart.data.datasets[0].data;
        function totalSum(total, datapoint) {
          return total + datapoint;
        }
        const totalValue = dataPoints.reduce(totalSum, 0);
        const percentageValue = ((value / totalValue) * 100).toFixed(1);
        return `${percentageValue}%`;
      },
    },
  },
};

const colors = ["#fca053", "#1c536b", "#a2e0f0", "#005a8d", "#5ab4ac"];

export const data = {
  labels: ["نجمة", "نجمتان", "3 نجوم", "4 نجوم", "5 نجوم"],
  datasets: [
    {
      label: "اشخاص",
      data: [10, 2, 3, 4, 5],
      backgroundColor: colors,
      borderColor: colors,
      borderWidth: 1,
    },
  ],
};

const CustomLegend = ({ name, color }) => {
  return (
    <Flex alignItems="center">
      <Box w="15px" h="15px" bg={color} borderRadius="50%" me="5px" />
      <Text>{name}</Text>
    </Flex>
  );
};

const ChartReviews = () => {
  return (
    <>
      <Doughnut options={options} data={data} />

      <Grid gridTemplateColumns="repeat(3,1fr)" gap="10px" mt="15px">
        {data?.labels.map((e, i) => {
          return <CustomLegend name={e} color={colors[i]} />;
        })}
      </Grid>
    </>
  );
};

export default ChartReviews;
