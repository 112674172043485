import { useState } from "react";
import { Box, Text, Grid, Flex } from "@chakra-ui/react";
import { Card } from "components/dashboard";
import ReviewCard from "components/dashboard/review-card";
import {
  CustomButton,
  CustomContainer,
  Loading,
  Nodata,
} from "components/shared";
import { FaFire } from "react-icons/fa";
import { MdReviews } from "react-icons/md";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getReport, getReviewsWithLimit } from "apis/tickets";
import moment from "moment";

const ColorChart = ({ value, name }) => {
  // Ensure the value is between 1 and 5
  const clampedValue = Math.max(1, Math.min(5, value));

  // Calculate width based on the value
  const widthPercentage = (clampedValue / 5) * 100;

  // Format percentage for display
  const percentageDisplay = widthPercentage.toFixed(2); // Two decimal places

  // Determine background color based on the value
  let backgroundColor;
  let label;

  if (clampedValue <= 2) {
    backgroundColor = "red.500"; // Red for 1 to 2
    label = "سئ";
  } else if (clampedValue <= 3) {
    backgroundColor = "#fbe200"; // Yellow for 2 to 3
    label = "جيد";
  } else {
    backgroundColor = "green.500"; // Green more than 3
    label = "جيد جدا";
  }

  return (
    <>
      <Text
        fontWeight="bold"
        mb="8px"
        fontSize={["initial", "initial", "14px", "initial"]}>
        {name}
      </Text>
      <Box px="8px " py="12px" bg="#f1f1f1" borderRadius="5px">
        <Flex justifyContent="space-between" mb="5px">
          <Text
            fontWeight="bold"
            fontSize={["initial", "initial", "14px", "initial"]}>
            {percentageDisplay}%
          </Text>
          <Text fontSize={["initial", "initial", "14px", "initial"]}>
            المتوسط :{" "}
            <Text as="span" fontWeight="bold">
              {label}
            </Text>
          </Text>
        </Flex>

        <Box
          width="100%"
          height={["8px", "8px", "5px", "8px"]}
          backgroundColor="gray.200"
          borderRadius="md"
          position="relative">
          <Box
            height="100%"
            width={`${widthPercentage}%`}
            backgroundColor={backgroundColor}
            borderRadius="md"
          />
        </Box>
      </Box>
    </>
  );
};

const Dashboard = () => {
  const [enterDate, setEnterDate] = useState(null);

  const { data, isLoading, refetch } = useQuery(
    ["get reviews", 8],
    getReviewsWithLimit
  );

  const { data: reportData, isLoading: isLoadingReport } = useQuery(
    ["get report", 8],
    getReport
  );

  const reviewsStatus = reportData?.data?.reviewsStatus;

  const topProducts = [
    {
      name: "عدد التقيمات",

      salesNumber: reportData?.data?.counts.countReviews,
      bg: "#5ab4ac",
    },
    {
      name: "عدد العملاء",

      salesNumber: reportData?.data?.counts.countClients,
      bg: "#005a8d",
    },
    {
      name: "عدد التقيمات اليوم",

      salesNumber: reportData?.data?.counts.countReviewsToday,
      bg: "#fca053",
    },
    {
      name: "عدد العملاء اليوم",

      salesNumber: reportData?.data?.counts.countClientsToday,
      bg: "#15a2b4",
    },
  ];

  if (isLoadingReport || isLoading) {
    return <Loading />;
  }

  return (
    <Box my="50px">
      <CustomContainer mx={["8%", " ", "auto", ""]}>
        <Box mb="50px">
          <Flex alignItems="center" mb="15px">
            <Text fontWeight="bold">تحليل العملاء والتقيمات</Text>
            <Box
              as={FaFire}
              color="text.secondColor"
              fontSize="26px"
              ms="5px"
            />
          </Flex>

          <Grid
            gridTemplateColumns={[
              "repeat(1,1fr)",
              "repeat(2,1fr)",
              "repeat(4,1fr)",
              "repeat(4,1fr)",
            ]}
            gap="15px">
            {topProducts?.map((e) => {
              return (
                <Card
                  img={e?.img}
                  name={e?.name}
                  salesNumber={e?.salesNumber}
                  bg={e?.bg}
                />
              );
            })}
          </Grid>
        </Box>
        {data?.data?.length > 0 ? (
          <Box>
            <Flex alignItems="center" mb="15px">
              <Text fontWeight="bold">العملاء وتقيماتهم</Text>
              <Box
                as={MdReviews}
                color="text.secondColor"
                fontSize="26px"
                ms="5px"
              />
            </Flex>
            <Grid
              gridTemplateColumns={[
                "repeat(1,1fr)",
                "repeat(1,1fr)",
                "3fr 1fr",
                "3fr 1fr",
              ]}
              gap="20px">
              <Box order={["2", " ", "1", ""]}>
                <Grid
                  gridTemplateColumns={[
                    "repeat(1,1fr)",
                    "repeat(2,1fr)",
                    "repeat(2,1fr)",
                    "repeat(2,1fr)",
                  ]}
                  gap="20px"
                  mb="30px">
                  {data?.data?.map((e) => {
                    return (
                      <ReviewCard
                        name={e?.authorName}
                        date={moment(e?.date).format("DD-MM-yyyy")}
                        notes={e?.questions[0]?.notes}
                        rating={e?.questions[0]?.rating}
                        reviews={e?.questions}
                      />
                    );
                  })}
                </Grid>
                {data?.data?.length < 8 ? null : (
                  <CustomButton
                    name="عرض المزيد"
                    w="250px"
                    as={Link}
                    to="/reviews"
                  />
                )}
              </Box>

              <Box
                bg="white"
                borderRadius="10px"
                pb="30px"
                order={["1 ", "1", "2", ""]}>
                <Box
                  bg="#f7f7f8"
                  borderRadius="10px"
                  border="2px solid #e0e0e0"
                  pb="20px">
                  <Text borderBottom="1px solid #e5e5e5" p="15px" mb="25px">
                    نسبة عدد النجوم
                  </Text>
                  <Box px="20px">
                    {reviewsStatus?.map((e) => {
                      return (
                        <Box mb="15px">
                          <ColorChart value={e?.rating} name={e?.serviceName} />
                        </Box>
                      );
                    })}
                  </Box>

                  {/* <Box px="30px" w="85%" m="auto">
                  <ChartReviews />
                </Box> */}
                </Box>
              </Box>
            </Grid>
          </Box>
        ) : (
          <Nodata />
        )}
      </CustomContainer>
    </Box>
  );
};

export default Dashboard;
