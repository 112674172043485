import { useState } from "react";
import { Box } from "@chakra-ui/react";
import { CustomInput, ErrorMsg, CustomButton, Label } from "components/shared";
import { Formik, Form } from "formik";
// import AddNewValidations from "validations/add-person";
import { useMutation } from "react-query";
import { createClient, updateClient } from "apis/tickets";
import clientSchema from "validations/client";

const AddClientModal = ({ refetch, closeModal, data }) => {
  const [apiError, setApiError] = useState({ phone: "" });

  const { mutate, isLoading, error } = useMutation(createClient, {
    onSuccess: () => {
      refetch();
      closeModal(false);
    },
    onError: (err) => {
      setApiError({ phone: err?.response?.data?.phone || "" });
    },
  });

  const errors = error?.response?.data;

  const { mutate: update, isLoading: isLoadingUpdate } = useMutation(
    updateClient,
    {
      onSuccess: () => {
        refetch();
        closeModal(false);
      },
      onError: (err) => {
        setApiError({ phone: err?.response?.data?.phone || "" });
      },
    }
  );

  return (
    <Formik
      initialValues={{
        name: data ? data?.name : "",
        phoneNumber: data ? data?.phoneNumber : "",
      }}
      validationSchema={clientSchema}
      onSubmit={(values) => {
        if (data) {
          update({ id: data?._id, data: values });
        } else {
          mutate(values);
        }
        //handleLogin({ email: values.email, password: values.password });
      }}>
      {(formik) => {
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Box mb="20px">
              <Label text="الاسم" />
              <CustomInput
                placeholder="اكتب الاسم هنا"
                onChange={formik.handleChange("name")}
                onBlur={formik.handleBlur("name")}
                value={formik.values.name}
              />
              {formik.errors.name && formik.touched.name && (
                <ErrorMsg text={formik.errors.name} />
              )}
            </Box>
            <Box mb="20px">
              <Label text="رقم الهاتف" />
              <CustomInput
                type="number"
                placeholder="اكتب رقم الهاتف هنا"
                onChange={(e) => {
                  formik.setFieldValue("phoneNumber", e?.target.value);
                  setApiError({ phone: "" });
                }}
                onBlur={formik.handleBlur("phoneNumber")}
                value={formik.values.phoneNumber}
              />
              {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                <ErrorMsg text={formik.errors.phoneNumber} />
              )}
              {apiError.phone && <ErrorMsg text={apiError.phone} />}
            </Box>

            <CustomButton
              type="submit"
              mt="25px"
              mb="5px"
              //   disabled={isLoading}
              loading={isLoading}
              name={data ? "حفظ" : "تسجيل"}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddClientModal;
