// context/AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import { CustomToast } from "components/shared";
import { useToast } from "@chakra-ui/react";

// Create a Context for authentication
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
    token: localStorage.getItem("token") || null,
  });

  const toast = useToast();
  // Function to log out
  const logout = () => {
    localStorage.removeItem("token");
    setAuthState({ isAuthenticated: false, user: null, token: null });

    toast({
      render: (props) => {
        return (
          <CustomToast
            title="تسجيل خروج"
            description=" تم تسجيل الخروج بنجاح"
            status="success"
            onClose={props.onClose}
          />
        );
      },
    });
  };

  // Function to check token validity
  const checkToken = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/user/refresh-token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authState.token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();

        setAuthState({
          isAuthenticated: true,
          user: data.user,
          token: data.token,
        });
      } else {
        // Token is invalid or expired
        logout();
      }
    } catch (error) {
      console.error("Token check failed:", error);
      logout();
    }
  };

  useEffect(() => {
    // Check if token is valid on app load
    if (authState.token) {
      checkToken();
    } else {
      setAuthState({ isAuthenticated: false, user: null, token: null });
    }
  }, [authState.token]);

  return (
    <AuthContext.Provider value={{ setAuthState, authState, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
