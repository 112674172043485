// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./style.css";

const Slider = ({ forms }) => {
  return (
    <>
      <Swiper
        spaceBetween={5}
        slidesPerView={1}
        allowTouchMove={false} // Disables touch and mouse drag swiping
        simulateTouch={false} // Disables mouse drag swiping
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        {forms?.map((e) => {
          return <SwiperSlide>{e}</SwiperSlide>;
        })}

        {/* <SlideNextButton />
        <SlidePrevButton /> */}
      </Swiper>
    </>
  );
};

export default Slider;
