import { useContext } from "react";
import { Box, Text, useToast } from "@chakra-ui/react";
import {
  CustomInput,
  CustomUploadFile,
  ErrorMsg,
  CustomToast,
  CustomButton,
} from "components/shared";
import avatar from "assets/images/profileDefault.png";
import { Formik, Form } from "formik";
import { useSwiper } from "swiper/react";
import { useMutation } from "react-query";
import { updateProfile } from "apis/tickets";
import { AuthContext } from "context/user-auth";

const ResturantInfo = ({ profileImgUrl, addressName, projectName }) => {
  const { setAuthState } = useContext(AuthContext);
  const swiper = useSwiper();
  const toast = useToast();

  const { mutate, isLoading } = useMutation(updateProfile, {
    onSuccess: (res) => {
      setAuthState((prev) => ({
        ...prev,
        user: res.data.user,
      }));

      toast({
        render: (props) => {
          return (
            <CustomToast
              title="تعديل الملف الشخصى"
              description=" تم تعديل الملف الشخصى بنجاح"
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  return (
    <Formik
      initialValues={{
        profileImg: profileImgUrl || "",
        projectName: projectName || "",
        addressName: addressName || "",
      }}
      //   validationSchema={signupValidations}
      onSubmit={(values) => {
        mutate(values);
        //console.log({ ...personalInfo, ...values });
      }}>
      {(formik) => {
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Text fontWeight="bold" mb="20px" fontSize="26px" color="#383838">
              التسجيل
            </Text>
            <Text mb="20px" color="#a2a2a2">
              سجل الأن واستمتع بجميع الخدمات التى نقدمها او كن شريك لنا واعمل
              معنا وحقق الربح الذى تريده
            </Text>

            <Box mb="20px">
              <CustomUploadFile
                radius={false}
                defaultImage={formik.values.profileImg || avatar}
                formikChange={(value) =>
                  formik.setFieldValue("profileImg", value)
                }
              />
            </Box>
            <Box mb="20px">
              <CustomInput
                label="اسم المشروع"
                placeholder="اسم مشروعك"
                onChange={formik.handleChange("projectName")}
                onBlur={formik.handleBlur("projectName")}
                value={formik.values.projectName}
              />
              {formik.errors.projectName && formik.touched.projectName && (
                <ErrorMsg text={formik.errors.projectName} />
              )}
            </Box>

            <Box mb="20px">
              <CustomInput
                label="العنوان"
                placeholder=" العنوان"
                onChange={formik.handleChange("addressName")}
                onBlur={formik.handleBlur("addressName")}
                value={formik.values.addressName}
              />
              {formik.errors.addressName && formik.touched.addressName && (
                <ErrorMsg text={formik.errors.addressName} />
              )}
            </Box>

            <CustomButton
              type="submit"
              mt="25px"
              mb="5px"
              name="حفظ"
              loading={isLoading}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ResturantInfo;
