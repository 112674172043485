import { Box, Text } from "@chakra-ui/react";

const SectionHeader = ({ title, desc, ...props }) => {
  return (
    <Box mb="50px" {...props}>
      <Text
        fontWeight="bold"
        fontSize={["20px", "35px", "45px", "45px"]}
        textAlign="center">
        {title}
      </Text>
      <Box w="60px" h="4px" bg="bg.mainColor" m="auto" my="10px" />
      <Text color="gray" fontSize="14px" textAlign="center">
        {desc}
      </Text>
    </Box>
  );
};

export default SectionHeader;
