import { Box } from "@chakra-ui/react";
import ReviewCard from "components/dashboard/review-card";
import moment from "moment";

const DetailsModals = ({ reviews }) => {
  return (
    <Box>
      {reviews?.map((e, i) => {
        return (
          <>
            <ReviewCard
              name={e?.questionInAr}
              date=""
              notes={e?.notes}
              rating={e?.rating}
              bg="none"
              border="none"
              p="0"
              borderRadius="0"
              noDetails
              boxShadow="none"
              showText
            />
            {reviews.length == 1 || i == reviews.length - 1 ? null : (
              <Box as="hr" my="15px" />
            )}
          </>
        );
      })}
    </Box>
  );
};

export default DetailsModals;
