import { useState, useEffect, useRef, memo } from "react";
import {
  Box,
  Text,
  Grid,
  Textarea,
  Flex,
  Image,
  useToast,
} from "@chakra-ui/react";
import { Formik, Form, FieldArray } from "formik";
import { Rating } from "react-simple-star-rating";
import { useMutation } from "react-query";
import { createReview } from "apis/tickets";
import clientSchema from "validations/client";
import EditFormModal from "./edit-form-modal";
import {
  CustomInput,
  ErrorMsg,
  CustomButton,
  Label,
  CustomModal,
  CustomToast,
} from "components/shared";
import love from "assets/images/love.svg";
import smileOpen from "assets/images/smile-open.svg";
import smile from "assets/images/smile.svg";
import sad from "assets/images/sad.svg";
import bad from "assets/images/bad.svg";
import moment from "moment";

const emojis = [
  { name: "terrible", value: 1, emoji: bad },
  { name: "bad", value: 2, emoji: sad },
  { name: "okay", value: 3, emoji: smile },
  { name: "good", value: 4, emoji: smileOpen },
  { name: "great", value: 5, emoji: love },
];

const Question = memo(({ remove, index, formik, question, rating, notes }) => {
  const [emojiRate, setEmojiRate] = useState(question.rating || null);

  useEffect(() => {
    setEmojiRate(question.rating);
  }, [question.rating]);

  const handleRating = (rate) => {
    formik.setFieldValue(rating, rate);
    setEmojiRate(rate);
  };

  return (
    <Box key={index}>
      <Text fontWeight="bold">{question.questionInAr}</Text>
      {formik.values.rateShapeType === "stars" ? (
        <Flex>
          <Rating
            className="con-stars-rating"
            onClick={handleRating}
            SVGclassName="rating-inline-block"
            rtl
          />
        </Flex>
      ) : (
        <Flex
          gap={["20px", "35px", "35px", "35px"]}
          my="10px"
          overflowX="auto"
          overflowY="hidden"
          css={{
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar for WebKit browsers
            },
            scrollbarWidth: "none", // Hide scrollbar for Firefox
            msOverflowStyle: "none", // Hide scrollbar for Internet Explorer and Edge
          }}>
          {emojis.map((e) => (
            <Box key={e.value}>
              <Grid
                placeItems="center"
                w={["60px", "70px", "70px", "70px"]}
                h={["60px", "70px", "70px", "70px"]}
                border="1px solid #dadada"
                borderColor={e.value === emojiRate ? "yellow" : "#dadada"}
                borderRadius="50%"
                mb="10px">
                <Image
                  src={e.emoji}
                  w={["40px", "50px", "50px", "50px"]}
                  cursor="pointer"
                  transition="0.3s"
                  filter={
                    e.value === emojiRate
                      ? "drop-shadow(0 4px 8px orange)"
                      : "none"
                  }
                  transform={e.value === emojiRate ? "scale(1.1)" : "scale(1)"}
                  onClick={() => {
                    formik.setFieldValue(rating, e.value);
                    setEmojiRate(e.value);
                  }}
                  _hover={{ transform: "scale(1.2)" }}
                />
              </Grid>
              <Text
                textAlign="center"
                fontSize="14px"
                color="#a0a0a0"
                textTransform="capitalize">
                {e.name}
              </Text>
            </Box>
          ))}
        </Flex>
      )}
      <Box mb="20px">
        <Label text="ملاحظات" />
        <Textarea
          resize="none"
          h="150px"
          placeholder="يسعدنا معرفة رايك"
          _focus={{ boxShadow: "none" }}
          name={notes}
          value={question.notes}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Box>
    </Box>
  );
});

const RateForm = ({ refetch, closeModal, data, isForUpdating, userId }) => {
  const [showEdit, setShowEdit] = useState(false);
  const formikRef = useRef();
  const toast = useToast();

  const { mutate, isLoading, isSuccess } = useMutation(createReview, {
    onSuccess: () => {
      toast({
        render: (props) => {
          return (
            <CustomToast
              title="شكرا لتقيمك"
              description=" تم ارسال تقيمك بنجاح"
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  useEffect(() => {
    if (formikRef.current && data?.questions) {
      formikRef.current.setFieldValue("questions", data.questions);
    }
    if (formikRef.current && data?.rateShapeType) {
      formikRef.current.setFieldValue("rateShapeType", data.rateShapeType);
    }
  }, [data]);

  return (
    <Grid placeItems="center" px={["15px", "15px", "0", " "]}>
      <Box
        w={["100%", "600px", "600px", "600px"]}
        m="auto"
        borderRadius="15px"
        boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;"
        overflow="hidden"
        bg="white">
        <Box borderBottom="2px solid #e0e0e0" p="20px">
          <Text
            textAlign="center"
            fontWeight="bold"
            fontSize={["18px", "20px", "24px", "24px"]}>
            {data?.title}
          </Text>
        </Box>

        <Formik
          innerRef={formikRef}
          initialValues={{
            name: "",
            phoneNumber: "",
            questions: data?.questions || [],
            rateShapeType: data?.rateShapeType || "emojies",
          }}
          validationSchema={clientSchema}
          onSubmit={(values) => {
            const isAtLeastOneServiceRated = values.questions?.find((e) => {
              return e.rating > 0;
            });

            if (isAtLeastOneServiceRated) {
              mutate({
                ...values,
                createdAt: moment(new Date()).format("DD-MM-yyyy"),
                userId,
              });
            } else {
              toast({
                render: (props) => {
                  return (
                    <CustomToast
                      title=" من فضلك "
                      description=" قيم خدمة واحدة على الاقل"
                      status="error"
                      onClose={props.onClose}
                    />
                  );
                },
              });
            }
          }}>
          {(formik) => (
            <Box
              p="20px"
              maxH={["initial", "600px", "600px", "600px"]}
              overflowY="auto">
              <Form>
                <Box mb="20px">
                  <Label text="الاسم" />
                  <CustomInput
                    type="text"
                    placeholder="الاسم هنا"
                    onChange={formik.handleChange("name")}
                    onBlur={formik.handleBlur("name")}
                    value={formik.values.name}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <ErrorMsg text={formik.errors.name} />
                  )}
                </Box>

                <Box mb="20px">
                  <Label text="رقم التليفون" />
                  <CustomInput
                    type="number"
                    placeholder="رقم التليفون هنا"
                    onChange={formik.handleChange("phoneNumber")}
                    onBlur={formik.handleBlur("phoneNumber")}
                    value={formik.values.phoneNumber}
                  />
                  {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                    <ErrorMsg text={formik.errors.phoneNumber} />
                  )}
                </Box>

                <FieldArray name="questions">
                  {({ push, remove }) => (
                    <>
                      {formik.values.questions.map((q, i) => (
                        <Question
                          key={i}
                          formik={formik}
                          question={q}
                          rating={`questions[${i}].rating`}
                          notes={`questions[${i}].notes`}
                        />
                      ))}
                    </>
                  )}
                </FieldArray>

                {isForUpdating ? (
                  <CustomButton
                    mt="25px"
                    mb="5px"
                    bg={data?.buttonBgColor || "bg.mainColor"}
                    bgColor={data?.buttonBgColor || "#3861fb"}
                    color={data?.buttonTextColor || "white"}
                    onClick={() => setShowEdit(true)}
                    name="تعديل"
                  />
                ) : isSuccess ? (
                  <Text
                    py="15px"
                    textAlign="center"
                    w="70%"
                    m="auto"
                    bg="#f1f1f1"
                    borderRadius="5px">
                    شكرا لتقيمك! تم الارسال بنجاح
                  </Text>
                ) : (
                  <CustomButton
                    type="submit"
                    mt="25px"
                    mb="5px"
                    bg={data?.buttonBgColor || "bg.mainColor"}
                    bgColor={data?.buttonBgColor || "#3861fb"}
                    color={data?.buttonTextColor || "white"}
                    loading={isLoading}
                    name="ارسال"
                  />
                )}
              </Form>
            </Box>
          )}
        </Formik>
      </Box>

      <CustomModal
        title="تعديل النموذج"
        show={showEdit}
        setShow={setShowEdit}
        overflow="outside"
        px="15px">
        <EditFormModal data={data} refetch={refetch} closeModal={setShowEdit} />
      </CustomModal>
    </Grid>
  );
};

export default RateForm;
