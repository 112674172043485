import { useContext } from "react";
import { Box, Flex, Grid, Text, Image, useToast } from "@chakra-ui/react";
import scan from "assets/images/scan.png";
import { CustomContainer, CustomButton, CustomToast } from "components/shared";
import { AuthContext } from "context/user-auth";
import ImageDownloader from "components/qr";
import { FaShare } from "react-icons/fa";

const Qr = () => {
  const { authState } = useContext(AuthContext);
  const toast = useToast();

  const copyLink = (rateLink) => {
    navigator.clipboard.writeText(rateLink);

    toast({
      render: (props) => {
        return (
          <CustomToast
            title="تم النسخ"
            description="  تم نسخ اللينك الى الحافظة بنجاح"
            status="success"
            onClose={props.onClose}
          />
        );
      },
    });
  };

  return (
    <Box bg="#f7f7f8" pb="40px">
      <CustomContainer>
        <Grid
          gridTemplateColumns={[
            "repeat(1,1fr)",
            "repeat(1,1fr)",
            "repeat(2,1fr)",
            "repeat(2,1fr)",
          ]}>
          <Flex
            flexDir="column"
            justifyContent="center"
            order={["2", "2", "1", "1"]}>
            <Box w={["95%", "80%", "80%", "80%"]} m={["auto", "", "0", ""]}>
              <Text
                textAlign={["center", "", "initial", ""]}
                fontSize={["35px", "40px", "45px", "60px"]}
                fontWeight="bold"
                lineHight="10px"
                mb="15px">
                شارك رمز الـ QR الخاص بك الآن!
              </Text>
              <Text
                textAlign={["center", "", "initial", ""]}
                fontSize={["14px", "16px", "", ""]}>
                بمشاركة رمز الـ QR مع عملائك، ستحصل على تقييمات فورية تساعدك في
                تحسين تجربتهم وتعزيز رضاهم. اجعل من السهل على عملائك تقديم
                آرائهم!{" "}
              </Text>

              <Flex gap="15px" justifyContent={["center", "initial", "", ""]}>
                <ImageDownloader base64Image={authState?.user?.qr} />
                <CustomButton
                  name="   لينك التقيم"
                  icon={FaShare}
                  w={["50%", "200px", " ", " "]}
                  m="0"
                  mt="15px"
                  bg="#ffbf60"
                  hoverColor="#fcbf66"
                  onClick={() =>
                    copyLink(`https://raiey.com/rate/${authState?.user?._id}`)
                  }
                />
              </Flex>
            </Box>
          </Flex>

          <Image
            src={scan}
            order={["1", "1", "2", "2"]}
            w={["80%", "60%", "initial", ""]}
            m={["auto", "", "0", ""]}
          />
        </Grid>
      </CustomContainer>
    </Box>
  );
};

export default Qr;
