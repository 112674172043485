import { Textarea } from "@chakra-ui/react";

const CustomTextarea = ({ ...props }) => {
  return (
    <Textarea
      resize="none"
      h="150px"
      _focus={{ boxShadow: "none" }}
      _active={{}}
      {...props}></Textarea>
  );
};

export default CustomTextarea;
