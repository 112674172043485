import { useState, useContext, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Textarea,
  Grid,
  Button,
  Radio,
  RadioGroup,
  Stack,
  Image,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import {
  CustomInput,
  CustomSelect,
  CustomUploadFile,
  ErrorMsg,
  CustomToast,
  CustomButton,
  CustomContainer,
} from "components/shared";
import { Link, useNavigate } from "react-router-dom";
import logo from "assets/images/logo1.svg";

import { Formik, Form } from "formik";
import Slider from "components/signup/slider.js";
import { useSwiper } from "swiper/react";
import signupPersonalValidations from "validations/signup-personal";
import { useMutation } from "react-query";
import { isUserExist } from "apis/tickets";

const SignupPersonalForm = ({ setPersonalInfo }) => {
  const swiper = useSwiper();
  const toast = useToast();

  const { mutate, isLoading } = useMutation(isUserExist, {
    onSuccess: () => {
      swiper.slideNext();
    },
    onError: (err) => {
      toast({
        render: (props) => {
          return (
            <CustomToast
              title={"حدث خطأ"}
              description={err?.response?.data?.errors?.alreadyExist}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  return (
    <Formik
      initialValues={{
        profileImg: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        password: "",
        confirmPassword: "",
      }}
      validationSchema={signupPersonalValidations}
      onSubmit={(values) => {
        setPersonalInfo(values);
        mutate(values.email);
      }}>
      {(formik) => {
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Text fontWeight="bold" mb="20px" fontSize="26px" color="#383838">
              حساب جديد
            </Text>
            <Text mb="20px" color="#a2a2a2">
              سجل الآن واستمتع بجميع الخدمات التي نقدمها، وكن من بين الأوائل
              الذين يحصلون على أحدث تقييمات وآراء العملاء.
            </Text>

            <Box mb="20px">
              <CustomInput
                placeholder="الأسم الأول"
                onChange={formik.handleChange("firstName")}
                onBlur={formik.handleBlur("firstName")}
                value={formik.values.firstName}
              />
              {formik.errors.firstName && formik.touched.firstName && (
                <ErrorMsg text={formik.errors.firstName} />
              )}
            </Box>
            <Box mb="20px">
              <CustomInput
                placeholder="الأسم الأخير"
                onChange={formik.handleChange("lastName")}
                onBlur={formik.handleBlur("lastName")}
                value={formik.values.lastName}
              />
              {formik.errors.lastName && formik.touched.lastName && (
                <ErrorMsg text={formik.errors.lastName} />
              )}
            </Box>
            <Box mb="20px">
              <CustomInput
                placeholder=" الايميل"
                onChange={formik.handleChange("email")}
                onBlur={formik.handleBlur("email")}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email && (
                <ErrorMsg text={formik.errors.email} />
              )}
            </Box>

            <Box mb="20px">
              <CustomInput
                placeholder="الهاتف"
                type="number"
                onChange={formik.handleChange("phone")}
                onBlur={formik.handleBlur("phone")}
                value={formik.values.phone}
              />
              {formik.errors.phone && formik.touched.phone && (
                <ErrorMsg text={formik.errors.phone} />
              )}
            </Box>

            <Box mb="20px">
              <CustomInput
                placeholder="الرقم السرى"
                type="password"
                onChange={formik.handleChange("password")}
                onBlur={formik.handleBlur("password")}
                value={formik.values.password}
              />
              {formik.errors.password && formik.touched.password && (
                <ErrorMsg text={formik.errors.password} />
              )}
            </Box>
            <Box mb="20px">
              <CustomInput
                type="password"
                placeholder="تأكيد الرقم السر"
                onChange={formik.handleChange("confirmPassword")}
                onBlur={formik.handleBlur("confirmPassword")}
                value={formik.values.confirmPassword}
              />
              {formik.errors.confirmPassword &&
                formik.touched.confirmPassword && (
                  <ErrorMsg text={formik.errors.confirmPassword} />
                )}
            </Box>

            <CustomButton
              type="submit"
              mt="25px"
              mb="5px"
              name="التالى"
              loading={isLoading}
            />

            <Text textAlign="center">
              هل لديك حساب بالفعل؟{" "}
              <Text
                as={Link}
                to="/login"
                fontWeight="bold"
                color="text.mainColor">
                سجل دخول الأن
              </Text>
            </Text>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignupPersonalForm;
