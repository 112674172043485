import { Box, Text, Grid, Image } from "@chakra-ui/react";
import {
  CustomInput,
  ErrorMsg,
  CustomButton,
  CustomContainer,
  CustomTextarea,
} from "components/shared";

import { Formik, Form } from "formik";

import { useMutation } from "react-query";
import hand from "assets/images/rev.png";
import { createContact } from "apis/tickets";
import contactSchema from "validations/contact";
import SectionHeader from "./section-header";

const ContactUs = () => {
  const { mutate, isLoading, isSuccess } = useMutation(createContact);

  return (
    <Box id="contactus">
      <CustomContainer>
        <SectionHeader title="  تواصل معنا" />

        <Grid
          gridTemplateColumns={[
            "repeat(1,1fr)",
            "repeat(2,1fr)",
            "repeat(2,1fr)",
            "repeat(2,1fr)",
          ]}
          gap="40px"
          border="2px solid #e0e0e0"
          borderRadius="10px"
          p="20px"
          w={["100%", "95%", "80%", "80%"]}
          mx="auto"
          mb="50px">
          <Grid placeItems="center" bg="#f7f7f8" borderRadius="10px">
            <Image src={hand} w="500px" />
          </Grid>
          <Formik
            initialValues={{
              name: "",
              email: "",
              message: "",
            }}
            validationSchema={contactSchema}
            onSubmit={(values) => {
              mutate(values);
            }}>
            {(formik) => {
              return (
                <Form onSubmit={formik.handleSubmit}>
                  <Text
                    fontWeight="bold"
                    mb="20px"
                    fontSize="26px"
                    color="#383838">
                    تواصل معنا
                  </Text>
                  <Text mb="20px" color="#a2a2a2">
                    لا تتردد في التواصل معنا. نحن هنا لدعمك والإجابة على جميع
                    أسئلتك. املأ النموذج أدناه وسنعود إليك في أقرب وقت.
                  </Text>

                  <Box mb="20px">
                    <CustomInput
                      placeholder="الأسم "
                      onChange={formik.handleChange("name")}
                      onBlur={formik.handleBlur("name")}
                      value={formik.values.name}
                    />
                    {formik.errors.name && formik.touched.name && (
                      <ErrorMsg text={formik.errors.name} />
                    )}
                  </Box>

                  <Box mb="20px">
                    <CustomInput
                      placeholder="الايميل"
                      type="string"
                      onChange={formik.handleChange("email")}
                      onBlur={formik.handleBlur("email")}
                      value={formik.values.email}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <ErrorMsg text={formik.errors.email} />
                    )}
                  </Box>

                  <Box mb="20px">
                    <CustomTextarea
                      placeholder="اكتب رسالتك هنا"
                      onChange={formik.handleChange("message")}
                      onBlur={formik.handleBlur("message")}
                      value={formik.values.message}
                    />
                    {formik.errors.message && formik.touched.message && (
                      <ErrorMsg text={formik.errors.message} />
                    )}
                  </Box>
                  {isSuccess ? (
                    <Text textAlign="center">تم الارسال بنجاح</Text>
                  ) : (
                    <CustomButton
                      type="submit"
                      mt="25px"
                      name="ارسال"
                      loading={isLoading}
                    />
                  )}
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </CustomContainer>
    </Box>
  );
};

export default ContactUs;

// بالطبع، إليك العناوين والوصف لكل قسم من الأقسام الثلاثة التي ذكرتها:

// قسم "Features"
// العنوان:
// "الميزات الرئيسية"

// الوصف:
// "اكتشف كيف يمكن لمنصتنا أن تساعدك في تحسين تجربة العملاء. من إدارة الأسئلة إلى تحليل التقييمات، نقدم لك الأدوات التي تحتاجها لتحقيق النجاح."

// قسم "How It Works"
// العنوان:
// "كيفية العمل"

// الوصف:
// "تعرف على كيفية الاستفادة القصوى من منصتنا عبر ثلاث خطوات بسيطة. سجّل، خصص أسئلتك، وابدأ في جمع وتحليل التقييمات بكل سهولة."

// قسم "Contact Us"
// العنوان:
// "اتصل بنا"

// الوصف:
// "إذا كان لديك أي استفسارات أو تحتاج إلى مساعدة، نحن هنا لدعمك. املأ النموذج أدناه وسنكون سعيدين بمساعدتك والرد على جميع أسئلتك في أقرب وقت ممكن."

// إذا كنت بحاجة إلى أي تعديلات أو إضافات، فأخبرني!

// خلى العناوين مش صريحة اوى كدة
// ChatGPT
// بالطبع، يمكن جعل العناوين أكثر جذبًا وغير مباشرة بعض الشيء. إليك النسخ المعدلة:

// قسم "Features"
// العنوان:
// "استكشف قوتنا"

// الوصف:
// "اكتشف كيف يمكن لمنصتنا أن تعزز تجربتك وتدعم نجاحك. تعرف على الأدوات والميزات التي نقدمها لتحسين كل جانب من جوانب عملك."

// قسم "How It Works"
// العنوان:
// "رحلتك معنا"

// الوصف:
// "تعلم كيف تبدأ رحلتك معنا في ثلاث خطوات بسيطة. من التسجيل إلى تحليل التقييمات، نقدم لك كل ما تحتاجه لتحقيق النجاح."

// قسم "Contact Us"
// العنوان:
// "دعنا نساعدك"

// الوصف:
// "هل تحتاج إلى مساعدة أو لديك استفسار؟ نحن هنا لدعمك. تواصل معنا وسنكون سعداء بالرد على جميع أسئلتك ومساعدتك في أقرب وقت ممكن."

// إذا كان هناك أي تفاصيل إضافية تود إضافتها، أخبرني وسأكون سعيدًا بالمساعدة!
