import { useState } from "react";
import { Box, Text, Grid } from "@chakra-ui/react";
import {
  CustomContainer,
  Search,
  CustomButton,
  CustomModal,
  Loading,
  Nodata,
} from "components/shared";
import { useQuery } from "react-query";
import { AddClientModal, ClientCard } from "components/clients";
import { IoMdAdd } from "react-icons/io";
import { getClients } from "apis/tickets";
import moment from "moment";

const Clients = () => {
  const [search, setSearch] = useState(null);
  const [showAdd, setShowAdd] = useState(false);

  let itemsPerPage = 8;
  const [page, setPage] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(null);

  const { data, isLoading, refetch } = useQuery(
    ["get clients", { search, page, itemsPerPage }],
    getClients
  );

  const loadNext = () => {
    if ((page + 1) * itemsPerPage <= data?.data?.total) {
      setPage(page + 1);
      setButtonLoading("next");
    }
  };

  const loadPrev = () => {
    if (page > 0) {
      setPage(page - 1);
      setButtonLoading("prev");
    }
  };

  return (
    <Box my="50px">
      <CustomContainer>
        <Text
          display={["block", "", "none", ""]}
          fontWeight="bold"
          fontSize={["25px", "45px", "45px", "45px"]}
          textAlign="center"
          textTransform="capitalize"
          mb="30px">
          جميع العملاء
        </Text>

        <Grid
          gridTemplateColumns={[
            "1fr auto",
            "repeat(2,1fr)",
            "repeat(3,1fr)",
            "repeat(3,1fr)",
          ]}
          mb="40px"
          gap={["15px", "15px", "", ""]}>
          <Box maxW={["initial", " ", "350px", "350px"]}>
            <Search
              setSearch={setSearch}
              placeholder=" البحث من خلال الاسم   "
            />
          </Box>

          <Text
            display={["none", "", "block", ""]}
            fontWeight="bold"
            fontSize={["25px", "45px", "45px", "45px"]}
            textAlign="center"
            textTransform="capitalize"
            mb="30px">
            جميع العملاء
          </Text>

          <CustomButton
            display={["none", "flex", "", ""]}
            name="اضافة عميل جديد "
            w={["100%", " ", "250px", "250px"]}
            m="0"
            icon={IoMdAdd}
            iconSize="24px"
            ms="auto"
            onClick={() => setShowAdd(true)}
          />

          <CustomButton
            display={["flex", "none", "", ""]}
            w={["100%", "", "250px", "250px"]}
            m="0"
            icon={IoMdAdd}
            iconSize="24px"
            ms="auto"
            onClick={() => setShowAdd(true)}
          />
        </Grid>

        {data?.data.total == 0 ? <Nodata /> : null}
        {isLoading ? (
          <Loading />
        ) : (
          <Grid
            gridTemplateColumns={[
              "repeat(1,1fr)",
              "repeat(2,1fr)",
              "repeat(3,1fr)",
              "repeat(4,1fr)",
            ]}
            gap="15px">
            {data?.data?.clients?.map((e) => {
              return (
                <ClientCard
                  userId={e?._id}
                  name={e?.name}
                  phoneNumber={"0" + e?.phoneNumber}
                  createdAt={moment(e?.createdAt).format("DD-MM-yyyy")}
                  ordersNumber="20"
                  reviewsNumber="20"
                  data={e}
                  refetch={refetch}
                />
              );
            })}
          </Grid>
        )}

        {isLoading ||
        (page == 0 && itemsPerPage >= data?.data?.total) ? null : (
          <Grid
            gridTemplateColumns="repeat(2,1fr)"
            gap="20px"
            w={["90%", "500px", "500px", "500px"]}
            m="auto">
            <CustomButton
              name="التالى"
              mt="40px"
              loading={isLoading && buttonLoading == "next"}
              onClick={loadNext}
              isDisabled={(page + 1) * itemsPerPage >= data?.data?.total}
            />

            <CustomButton
              name="السابق"
              mt="40px"
              loading={isLoading && buttonLoading == "prev"}
              onClick={loadPrev}
              isDisabled={page == 0}
            />
          </Grid>
        )}
      </CustomContainer>
      <CustomModal title="اضافة عميل جديد" show={showAdd} setShow={setShowAdd}>
        <AddClientModal closeModal={setShowAdd} refetch={refetch} />
      </CustomModal>
    </Box>
  );
};

export default Clients;
