import { useRef } from "react";
import { Flex, Input, Grid, Box } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import CustomButton from "./custom-button";

const Search = ({ placeholder, setSearch, ...props }) => {
  const inputRef = useRef();

  return (
    <Box
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        setSearch(inputRef.current.value);
      }}>
      <Flex alignItems="center" w={["100%", "", "initial", ""]}>
        <Input
          fontSize={["14px", "initial", "", ""]}
          background="white"
          placeholder={placeholder}
          h="50px"
          borderEndRadius="5px"
          borderStartRadius="0"
          borderInlineEnd="0"
          borderColor="#E2E8F0"
          ref={inputRef}
          _focus={{ boxShadow: "none", borderColor: "#E2E8F0" }}
          //   onChange={(e) => {
          //     setSearch(e.target.value);
          //   }}
          {...props}
        />
        <CustomButton
          name={<Box as={FiSearch} color="black" />}
          display="grid"
          placeItems="center"
          h="50px"
          bg="#f8f9fa"
          w="60px"
          borderEndRadius="0"
          borderStartRadius="5px"
          border="1px solid #E2E8F0"
          type="submit"
          cursor="pointer"
          p="0"
          _hover={{ bg: "#f8f9fa" }}
        />
      </Flex>
    </Box>
  );
};

export default Search;
